import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { XpModalService } from './xp-modal.service';
@Component({
  selector: 'xp-modal',
  template: `
    <div cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOverlayOpen">
      <div class="fade modal-backdrop" (click)="closeModal()" [ngClass]="{ in: addInClass }"></div>
      <div class="modal fade new-dialog" [ngClass]="{ in: addInClass }" style="display: block;">
        <div class="modal-dialog" [ngClass]="modalClass">
          <div *ngIf="!isBack" class="modal-btn-left">
            <div
              class="modal-btn-close"
              (click)="closeModal()"
              [matTooltip]="closeButtonText"
              matTooltipClass="below wide"
            ></div>
            <div class="modal-btn-expand" (click)="toggleFullScreen()">
              <span>{{ isFullScreen ? 'Collapse modal' : 'Expand modal to full screen' }}</span>
            </div>
          </div>
          <div *ngIf="isBack" class="modal-btn-back new" (click)="closeModal()">
            <span>{{ closeButtonText }}</span>
          </div>
          <div class="modal-body no-padding">
            <ng-template [ngTemplateOutlet]="modalBody"></ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  `,
})
export class XpModalComponent implements OnChanges, AfterViewInit {
  @Input() closeButtonText = 'Close';
  @Input() titleText = '';
  @Input() descriptionText = '';
  @Input() saveButtonText = 'Save changes';
  @Input() isOpen = false;
  @Input() isSmall = false;
  @Input() isVerySmall = false;
  @Input() isBack = false;
  @Input() id = '';
  @Output() close = new EventEmitter();
  @ContentChild(TemplateRef) modalBody: TemplateRef<any>;

  isFullScreen = false;
  addInClass = false;
  isOverlayOpen = false;

  constructor(private xpModalService: XpModalService) {}

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.id && changes.id?.currentValue !== changes.id?.previousValue) {
    //   this.isFullScreen = this.xpModalService.getModalData(this.id);
    // }

    const { isOpen } = changes;
    if (isOpen && isOpen.currentValue && !isOpen.previousValue) {
      this.isOverlayOpen = true;
      setTimeout(() => {
        this.addInClass = true;
      }, 200);
    }

    if (isOpen && !isOpen.currentValue && isOpen.previousValue) {
      this.addInClass = false;
      setTimeout(() => {
        this.isOverlayOpen = false;
      }, 200);
    }
  }

  closeModal() {
    this.close.emit();
  }

  get modalClass(): string {
    if (this.isFullScreen) {
      return 'modal-full-screen';
    }

    if (this.isVerySmall) {
      return 'very-thin';
    }
    if (this.isSmall) {
      return 'thin';
    }

    return 'modal-lg';
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    this.xpModalService.setFullScreen(this.isFullScreen, this.id);
  }
}
