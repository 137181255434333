import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, takeWhile, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { AppState } from '../../store';
import {
  clearHistory,
  closePackageImportModal,
  closePackageVersionMessageModal,
  createMajorVersion,
  createMajorVersionError,
  cutAction,
  exportJSON,
  exportJSONError,
  exportJSONSuccess,
  getValidationStatus,
  importJSON,
  importJSONError,
  openComponentsModal,
  removeComponents,
  runValidationAction,
  runValidationError,
  savePackage,
  savePackageError,
  savePackageSuccess,
  validationStatusError,
  validationStatusSuccess,
} from './package-designer.actions';
import { setComponent, updateRawComponent } from './component.actions';
import { findComponentById, fixComponentData, getDataFromComponent } from '../helpers/components.helpers';
import { PackagesResource } from '../../packages/resources/packages.resource';
import { NotifyService } from '../../common/services/notify.service';
import { setPackageFromJson } from './package.actions';
import { openJobsModal } from '../../jobs/store/jobs.actions';
import { getConnectionSchemaResponse } from '../../connections/store/connections.actions';
import { COMPONENT_TYPE } from '../../constants/component_type';
import { adjustRelationshipField } from '../components/component-editors-helpers/schema-importer.component';
import { AllComponentData, AnyComponentData } from '../package.models';
import { SALESFORCE_API_VERSION } from '../../constants/salesforce_api_versions';
import { Package } from '../../packages/package.models';

function downloadObjectAsJson(exportObj: any, exportName: string) {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(exportObj, null, 4))}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${exportName}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

function setDefaultValuesForComponent(
  component: AllComponentData,
  componentType: COMPONENT_TYPE,
  defaults: Partial<AnyComponentData>,
): AllComponentData {
  if (
    componentType === COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT ||
    componentType === COMPONENT_TYPE.SALESFORCE_DESTINATION_COMPONENT
  ) {
    if (!(component as any).is_new && !component.api_version) {
      return { ...defaults, ...component, api_version: SALESFORCE_API_VERSION.V1 } as any;
    }
  }
  return { ...defaults, ...component } as any;
}

function cleanPackageFromUnnecessaryAttributes(packageItem: Partial<Package>): Partial<Package> {
  const clonedPackage = cloneDeep(packageItem);
  delete clonedPackage.version_description;
  clonedPackage.components = (clonedPackage.components || []).map((component) => {
    const keys = Object.keys(component);
    if (keys.length === 1) {
      const componentType = keys[0];

      delete component[componentType].is_new;
      return component;
    } else {
      return component;
    }
  });
  return clonedPackage;
}

@Injectable({
  providedIn: 'root',
})
export class PackageDesignerEffects {
  openComponentsModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openComponentsModal),
      withLatestFrom(this.store$),
      map(([{ componentId }, store]) => {
        const component = (store.packageDesigner.package.components || []).find(findComponentById(componentId));
        if (!component) {
          return { type: 'Empty' };
        }
        const fixedComponent = fixComponentData(component);
        return setComponent({
          rawComponent: setDefaultValuesForComponent(
            getDataFromComponent(component),
            fixedComponent.componentType,
            fixedComponent.defaults,
          ),
          component: fixedComponent,
        });
      }),
    ),
  );

  exportJSON$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exportJSON),
      withLatestFrom(this.store$),
      mergeMap(([_, store]) =>
        this.packagesResource.export(store.packageDesigner.package.id).pipe(
          map((res: any) => {
            const fileName = store.packageDesigner.package.name.toLowerCase().replace(/\s/g, '_');
            downloadObjectAsJson(res, fileName);
            return exportJSONSuccess();
          }),
          catchError((err) => of(exportJSONError({ err }))),
        ),
      ),
    ),
  );

  importJSON$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importJSON),
      withLatestFrom(this.store$),
      mergeMap(([{ body }, store]) => {
        let json: any;
        try {
          json = JSON.parse(body);
        } catch (e) {
          this.notifyService.error(this.translate.instant('response.422.invalid_json_message'));
          return of(importJSONError({ err: e }));
        }

        const { variables, metadata, components, edges, secret_variables } = json;
        const data_flow_json: any = { components, edges };
        const name = json.metadata && json.metadata.name ? json.metadata.name : 'Untitled package';
        const description = json.metadata && json.metadata.description ? json.metadata.description : '';

        return this.packagesResource
          .update(
            store.packageDesigner.package.id,
            {
              data_flow_json,
              variables,
              name,
              description,
              metadata,
              secret_variables,
            },
            { include: 'flow', auto_map_connections: true } as any,
          )
          .pipe(
            map((res) => {
              this.notifyService.success(
                this.translate.instant('designer.controls.actions.import_json_package.success'),
              );
              return setPackageFromJson({ packageItem: res });
            }),
            catchError((err) => of(exportJSONError({ err }))),
          );
      }),
    ),
  );

  setPackageFromJson$ = createEffect(() =>
    this.actions$.pipe(ofType(setPackageFromJson), map(closePackageImportModal)),
  );

  setPackageFromJson2$ = createEffect(() =>
    this.actions$.pipe(ofType(setPackageFromJson), map(closePackageVersionMessageModal)),
  );

  setPackageFromJson3$ = createEffect(() => this.actions$.pipe(ofType(setPackageFromJson), map(clearHistory)));

  createMajorVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMajorVersion),
      withLatestFrom(this.store$),
      mergeMap(([{ message }, store]) => {
        const packageItem = cleanPackageFromUnnecessaryAttributes(store.packageDesigner.package);
        packageItem.version_description = message;
        packageItem.data_flow_json = JSON.stringify({
          components: packageItem.components,
          edges: store.packageDesigner.package.edges,
        });

        if (packageItem.metadata) {
          packageItem.metadata.name = packageItem.name;
          packageItem.metadata.description = packageItem.description;
        }

        return this.packagesResource.majorVersion(store.packageDesigner.package.id, packageItem).pipe(
          map((res) => {
            this.notifyService.success(
              this.translate.instant('designer.controls.actions.create_major_version.success', {
                version: res.package_version,
              }),
            );
            return setPackageFromJson({ packageItem: { ...packageItem, ...res, data_flow_json: '{}' } });
          }),
          catchError((err) => of(createMajorVersionError({ err }))),
        );
      }),
    ),
  );

  savePackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savePackage),
      withLatestFrom(this.store$),
      mergeMap(([{ openJobModal, runValidation }, store]) => {
        const packageItem = cleanPackageFromUnnecessaryAttributes(store.packageDesigner.package);
        packageItem.data_flow_json = JSON.stringify({
          components: packageItem.components,
          edges: store.packageDesigner.package.edges,
        });

        if (packageItem.metadata) {
          packageItem.metadata.name = packageItem.name;
          packageItem.metadata.description = packageItem.description;
        }

        Object.keys(packageItem.secret_variables || {}).forEach((key) => {
          if (packageItem.secret_variables[key] && packageItem.secret_variables[key].includes('••••••••')) {
            delete packageItem.secret_variables[key];
          }
        });

        return this.packagesResource.update(store.packageDesigner.package.id, packageItem).pipe(
          map((res) => {
            this.notifyService.success(this.translate.instant('designer.controls.actions.save_package.success'));
            return savePackageSuccess({ openJobModal, runValidation });
          }),
          catchError((err) => of(savePackageError({ err }))),
        );
      }),
    ),
  );

  savePackageSuccess$ = createEffect(() => this.actions$.pipe(ofType(savePackageSuccess), map(clearHistory)));
  savePackageSuccessOpenModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savePackageSuccess),
      map(({ openJobModal }) => (openJobModal ? openJobsModal() : { type: 'empty' })),
    ),
  );
  savePackageSuccessValidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savePackageSuccess),
      map(({ runValidation }) => (runValidation ? runValidationAction() : { type: 'empty' })),
    ),
  );

  runValidationAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(runValidationAction),
      withLatestFrom(this.store$),
      mergeMap(([_, store]) => {
        return this.packagesResource.validate(store.packageDesigner.package.id).pipe(
          map((res) => {
            return getValidationStatus({ id: res.id });
          }),
          catchError((err) => of(runValidationError({ err }))),
        );
      }),
    ),
  );

  pollValidationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getValidationStatus),
      withLatestFrom(this.store$),
      mergeMap(([{ id }, store]) =>
        this.startPolling(id, store.packageDesigner.package.id).pipe(
          takeWhile((res) => res.status === 'running', true),
          map((res: any) => {
            if (res.status === 'running') {
              return { type: 'empty' };
            }
            return res.status === 'completed'
              ? validationStatusSuccess()
              : validationStatusError({ err: res.errors.length ? res.errors : [res.errors || {}] });
          }),
          catchError((err) => of(validationStatusError({ err: err.length ? err : [err || {}] }))),
        ),
      ),
    ),
  );

  cutAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cutAction),
      map(() => removeComponents({})),
    ),
  );

  getConnectionSchemaResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConnectionSchemaResponse),
      withLatestFrom(this.store$),
      map(([{ connectionSchema, hardRefresh, access_mode }, store]) => {
        if (!store.packageDesigner.component) {
          return { type: 'empty' };
        }

        const isCloudStorageComponent =
          store.packageDesigner.component.componentType === COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT;
        const isSalesforceSourceComponent =
          store.packageDesigner.component.componentType === COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT;
        const isQueryAccessMode = access_mode === 'query';
        const hasEmptyFields = store.packageDesigner.rawComponent?.schema?.fields?.length === 0;

        if (
          ((isCloudStorageComponent || (isSalesforceSourceComponent && isQueryAccessMode)) && hasEmptyFields) ||
          hardRefresh
        ) {
          return updateRawComponent({
            rawComponent: {
              schema: {
                ...store.packageDesigner.rawComponent.schema,
                fields: connectionSchema.map((field) => ({ ...field, id: uuidv4() })).map(adjustRelationshipField),
              },
            },
          });
        }

        return { type: 'empty' };
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private packagesResource: PackagesResource,
    private notifyService: NotifyService,
    private translate: TranslateService,
  ) {}

  private startPolling(validation_id: number, package_id: number, interval: number = 3000): Observable<any> {
    return timer(0, interval).pipe(switchMap(() => this.packagesResource.validateStatus(package_id, validation_id)));
  }
}
