import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Connection, LambdaTransformationComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { ComponentTypeItem } from '../../../constants/component_types';
import { PackagesResource } from '../../../packages/resources/packages.resource';

@Component({
  selector: 'lambda-transformation-editor',
  template: `
    <div class="lambda-transformation-editor">
      <h4>Python Code</h4>

      <xp-form-validation type="Xplenty::JobAuthoring::Components::LambdaTransformationComponent">
        <form name="componentForm" novalidate #form="ngForm" class="lambda-transformation-editor-form">
          <div class="row">
            <xp-form-group class="col-md-12">
              <label for="code"><b></b></label>
              <code-editor
                [value]="rawComponent.code"
                [options]="editorOptions"
                name="code"
                (valueChange)="onCodeChange($event)"
              ></code-editor>
            </xp-form-group>
          </div>
          <div class="row">
            <xp-form-group class="col-md-8">
              <div class="form-group">
                <label for="batch_size"
                  ><b>{{ 'lambda_transformation_component.labels.batch_size' | translate }} </b>
                  <br />
                  {{ 'lambda_transformation_component.labels.batch_size_hint' | translate }}
                </label>
                <xp-input
                  type="text"
                  class="form-control"
                  name="batch_size"
                  id="batch_size"
                  [ngModel]="rawComponent.batch_size"
                  (ngModelChange)="onBatchSizeChange($event)"
                ></xp-input>
              </div>
            </xp-form-group>
          </div>
        </form>
      </xp-form-validation>

      <h4>Test Code with payload</h4>
      <hr />

      <div class="row test-payload-container">
        <div class="col-md-12">
          <label for="response_type">Test Payload</label>
          <xp-fields-collection
            [records]="testPayloadRecords"
            [collectionSettings]="collectionSettings"
            [isValid]="true"
            (recordsChange)="onFieldsChange($event)"
            [columns]="columns"
          >
            <ng-container *ngFor="let field of fields">
              <ng-template [templateName]="field.name" let-item>
                <xp-database-column-editor
                  [hideEditor]="true"
                  [value]="item.record[field.name]"
                  [index]="item.index"
                  [propName]="field.name"
                  (fieldChange)="onTestPayloadFieldChange($event, item.record, field.name)"
                  class="fields-collection-editor"
                ></xp-database-column-editor>
              </ng-template>
              <ng-template [templateName]="field.name + '-header'" let-item>
                <span>{{ field.name === this.getCustomIdFieldName() ? 'id' : field.name }}</span>
              </ng-template>
            </ng-container>
          </xp-fields-collection>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-primary test-code-button" (click)="testCode()">Test Code</button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12" *ngIf="errorMessage">
          <div class="alert alert-warning">
            {{ errorMessage }}
          </div>
        </div>
      </div>

      <schema-importer-data-preview
        [data]="dataPreview"
        [component]="component"
        [rawComponent]="rawComponent"
        [isPreviewLoading]="false"
        [isPreviewLoading]="isPreviewLoading"
        customLabel="Response from test code"
      ></schema-importer-data-preview>
    </div>
  `,
})
export class LambdaTransformationEditorComponent extends BaseForm implements BaseFormInterface {
  @Input() rawComponent: LambdaTransformationComponentData;
  @Input() parentSchemas: Schema[];
  @Input() component: ComponentTypeItem;
  @Input() packageId: number;
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';
  isFormValid = false;
  validationChangeSubscription: Subscription;
  testPayloadRecords = [];
  collectionSettings = {};
  fields = [];
  columns = [];
  selectPickerTypes = SelectPickerTypes;
  errorMessage = '';
  testResult;
  dataPreview;
  isPreviewLoading = false;

  editorOptions: any = {
    mode: 'text/x-python',
    placeholder: 'print("Hello, World!")',
  };

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    protected packagesResource: PackagesResource,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;
      this.onValidityChange();
    });
    let fields = (this.parentSchemas[0] || {}).fields || ([] as any);
    fields = fields.map((field) => (field.name === 'id' ? { name: this.getCustomIdFieldName() } : field));

    this.fields = fields;
    this.columns = fields.map((field) => field.name);
    this.store.dispatch(updateRawComponent({ rawComponent: { fields } }));

    const emptyRecord = {
      FC_pristine: true,
      ...fields.reduce((acc, field) => {
        acc[field.name] = '';
        return acc;
      }, {}),
    };

    this.testPayloadRecords = this.rawComponent.test_payload_records || [emptyRecord];

    this.collectionSettings = {
      itemsPerPage: 10,
      emptyRecord,
      hideSearch: true,
    };
  }

  onValidityChange() {
    const isValid = this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onBatchSizeChange(batch_size: number) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { batch_size },
      }),
    );
    this.store.dispatch(updateComponent({ component: { batch_size } }));
  }

  onCodeChange(code: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { code },
      }),
    );
    this.store.dispatch(updateComponent({ component: { code } }));
  }

  onFieldsChange({ records }: any) {
    this.testPayloadRecords = records;
    this.store.dispatch(updateRawComponent({ rawComponent: { test_payload_records: records } }));
  }

  onTestPayloadFieldChange(value: string, record: any, field: string) {
    const newRecords = this.testPayloadRecords.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });
    this.testPayloadRecords = newRecords;
    this.store.dispatch(updateRawComponent({ rawComponent: { test_payload_records: newRecords } }));
  }

  getCustomIdFieldName() {
    return '__id_field';
  }

  testCode() {
    this.isPreviewLoading = true;
    this.packagesResource
      .invokeLambda(this.packageId, {
        payload: this.testPayloadRecords.map((record) => {
          const newRecord = { ...record };

          if (record[this.getCustomIdFieldName()]) {
            newRecord['id'] = record[this.getCustomIdFieldName()];
            delete newRecord[this.getCustomIdFieldName()];
          }

          delete newRecord['FC_pristine'];

          return newRecord;
        }),
        code: this.rawComponent.code,
        input_alias: this.rawComponent.id,
      })
      .subscribe({
        next: (res) => {
          this.errorMessage = '';
          this.testResult = res;
          this.isPreviewLoading = false;
          this.dataPreview = {
            data: res.map((record) =>
              this.fields.map((field) => record[field.name === this.getCustomIdFieldName() ? 'id' : field.name]),
            ),
            fields: this.fields.map((field) => ({
              [field.name === this.getCustomIdFieldName() ? 'id' : field.name]: 'string',
            })),
          };
        },
        error: (err) => {
          this.testResult = null;
          this.errorMessage = err.error?.error || err.error?.message;
          this.isPreviewLoading = false;
        },
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
