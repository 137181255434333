import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericListType, LoadMoreQuery } from '../../common/components/lists/generic-list.component';
import { AppState } from '../../store';
import {
  closeWorkspacesModal,
  getWorkspacesList,
  loadMoreWorkspacesList,
  openWorkspacesModal,
  searchWorkspacesList,
} from '../store/workspaces.actions';
import {
  selectAreAllWorkspacesLoaded,
  selectWorkspaces,
  selectWorkspacesErrorFlag,
  selectWorkspacesIsModalOpenFlag,
  selectWorkspacesLoadingFlag,
} from '../store/workspaces.selectors';
import { Workspace, WorkspacePackageItem } from '../workspaces.models';
import { AssistedSearchWorkspacesConfiguratorService } from '../../common/services/assisted-search/assisted-search-workspaces-configurator.service';
import { SearchConfig } from '../../common/services/assisted-search/search-config.model';
import { QueryParamsWorkspacesList } from '../../common/helper/query-params-generic-list.helper';
import { AuthorizationGuard } from '../../common/services/authorization.guard';
import { WorkspacesResource } from '../resources/workspaces.resource';
import { NotifyService } from '../../common/services/notify.service';
import { Subscription } from 'rxjs';
import { filter, first, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'workspaces-list',
  template: `
    <generic-list
      [type]="type"
      [items]="workspaces$ | async"
      [isLoading]="workspacesLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openCreateModal()"
      [newBtnText]="'workspace.generic-object.buttons.new' | translate"
      [emptyTitle]="'workspace.generic-object.empty.title' | translate"
      [emptyDescription]="'workspace.generic-object.empty.description' | translate"
      (loadMore)="loadMoreWorkspaces($event)"
      [allItemsLoaded]="areAllWorkspacesLoaded$ | async"
      [limit]="queryParams.limit"
      [searchPlaceholder]="'workspace.generic-object.placeholders.search' | translate"
      [searchConfig]="searchConfig"
      [searchQueryFromUrl]="searchQueryFromUrl"
      [hasSearch]="true"
      [isError]="workspacesLoadingError$ | async"
      [offset]="queryParams.offset"
      (searchSubmit)="onSearchSubmit($event)"
    >
      <ng-template templateName="listItem" let-item>
        <workspace-list-item
          [item]="item"
          [searchValue]="searchValue"
          (edit)="editWorkspace($event)"
        ></workspace-list-item>
      </ng-template>
    </generic-list>
    <xp-modal
      id="workspace-form-modal"
      [isOpen]="isModalOpen$ | async"
      [closeButtonText]="'workspace.generic-object.buttons.close' | translate"
      [saveButtonText]="'workspace.generic-object.buttons.save' | translate"
      [titleText]="'workspace.generic-object.title' | translate"
      (close)="onModalClose()"
    >
      <ng-template>
        <workspace-form [item]="formItem" [isLoadingItem]="isLoadingItem"></workspace-form>
      </ng-template>
    </xp-modal>
  `,
})
export class WorkspacesListComponent implements AfterViewInit, OnInit, OnDestroy {
  type = GenericListType.workspaces;
  workspacesLoading$ = this.store.select(selectWorkspacesLoadingFlag);
  workspacesLoadingError$ = this.store.select(selectWorkspacesErrorFlag);
  isModalOpen$ = this.store.select(selectWorkspacesIsModalOpenFlag);
  workspaces$ = this.store.select(selectWorkspaces);
  areAllWorkspacesLoaded$ = this.store.select(selectAreAllWorkspacesLoaded);
  queryParams = QueryParamsWorkspacesList;
  formItem: Partial<Workspace> = {};
  searchQueryFromUrl = '';
  searchConfig: SearchConfig = {};
  isLoadingItem = false;
  workspacesSubscription: Subscription;
  searchQuery = '';
  searchValue = '';

  constructor(
    private store: Store<AppState>,
    private assistedSearchWorkspacesConfigurator: AssistedSearchWorkspacesConfiguratorService,
    private router: Router,
    private route: ActivatedRoute,
    private authGuard: AuthorizationGuard,
    private workspacesResource: WorkspacesResource,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    const query = this.route.snapshot.queryParams.query;

    this.searchQueryFromUrl = query || '';

    this.assistedSearchWorkspacesConfigurator.getConfig$().subscribe((config) => {
      this.searchConfig = config;
    });

    if (!query) {
      this.store.dispatch(getWorkspacesList({ params: this.queryParams, isWorkspacesView: true }));
    }

    this.workspacesSubscription = this.workspaces$
      .pipe(withLatestFrom(this.areAllWorkspacesLoaded$))
      .subscribe(([workspaces, areAllWorkspacesLoaded]) => {
        if (workspaces.length < 8 || areAllWorkspacesLoaded) {
          return;
        }

        if (workspaces.length === 8) {
          const offset = 8;
          this.loadMoreWorkspaces({ ...this.queryParams, offset, limit: 8 });
          this.queryParams = {
            ...this.queryParams,
            limit: 8,
            offset,
          };
        }

        if (workspaces.length === 16) {
          const offset = 16;
          this.loadMoreWorkspaces({ ...this.queryParams, offset, limit: 8 });
          this.queryParams = {
            ...this.queryParams,
            limit: 8,
            offset,
          };
        }

        if (workspaces.length === 24) {
          const offset = 24;
          this.loadMoreWorkspaces({ ...this.queryParams, offset, limit: 8 });
          this.queryParams = {
            ...this.queryParams,
            limit: 8,
            offset,
          };
        }
      });
  }

  ngAfterViewInit() {
    if (this.router.url.includes('/workspaces/new')) {
      this.openCreateModal();
    }

    if (this.router.url.match(/\/workspaces\/\w+\/edit/)) {
      const { workspace_id } = this.route.snapshot.params;

      this.editWorkspace({ id: workspace_id } as Workspace);
    }
  }

  editWorkspace(item: Workspace) {
    const accountId = this.authGuard.account?.account_id;
    this.workspacesResource.get(item.id, { include: 'packages' }).subscribe({
      next: (workspaceItem) => {
        this.formItem = {
          ...workspaceItem,
          packages: ((workspaceItem.packages as WorkspacePackageItem[]) || []).filter(
            (packageItem) => packageItem.package.status !== 'archived',
          ),
        };
        this.isLoadingItem = false;
      },
      error: () => {
        this.notify.error('An error occurred while loading workspace item.');
        this.isLoadingItem = false;
      },
    });
    this.formItem = {
      ...item,
      packages: ((item.packages as WorkspacePackageItem[]) || []).filter(
        (packageItem) => packageItem.package.status !== 'archived',
      ),
    };
    this.router.navigate([`/${accountId}/workspaces/${item.id}/edit`]);
    this.store.dispatch(openWorkspacesModal());
    this.isLoadingItem = true;
  }

  openCreateModal(): void {
    const accountId = this.authGuard.account?.account_id;
    this.router.navigate([`/${accountId}/workspaces/new`]);
    this.formItem = {};
    this.store.dispatch(openWorkspacesModal());
  }

  onModalClose(): void {
    const queryParams = this.searchQuery ? { query: this.searchQuery } : {};
    const accountId = this.authGuard.account?.account_id;
    this.router.navigate([`/${accountId}/workspaces`], { queryParams, queryParamsHandling: 'merge' });
    this.store.dispatch(closeWorkspacesModal());
  }

  loadMoreWorkspaces(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreWorkspacesList({ params }));
  }

  onSearchSubmit({ query, text }) {
    const params = { ...this.queryParams, q: query, offset: 0 };
    this.queryParams = params;
    this.searchQuery = query;

    if (!query) {
      this.store.dispatch(getWorkspacesList({ params: this.queryParams }));
    } else {
      this.store.dispatch(searchWorkspacesList({ params }));
    }

    this.workspacesLoading$
      .pipe(
        filter((isLoading) => !isLoading),
        first(),
      )
      .subscribe(() => {
        this.searchValue = text || '';
      });
  }

  ngOnDestroy() {
    if (this.workspacesSubscription) {
      this.workspacesSubscription.unsubscribe();
    }
  }
}
