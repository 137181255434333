import { Cluster, Creator } from '../clusters/clusters.models';
import { Package } from '../packages/package.models';
import { Variables } from '../package-designer/package.models';

export enum JobStatus {
  running = 'running',
  pending = 'pending',
  idle = 'idle',
  failed = 'failed',
  completed = 'completed',
  stopped = 'stopped',
  stopping = 'stopping',
  pending_stoppage = 'pending_stoppage',
  queued = 'queued',
}

export interface JobOutput {
  can_download: boolean;
  can_preview: boolean;
  component: {
    fields: string[];
    name: string;
    type: string;
  };
  created_at: string;
  id: number;
  import_url?: string;
  name: string;
  path: string;
  preview_type: string;
  preview_url: string;
  records_count: number;
  updated_at: string;
  url: string;
  preview?: string;
  error?: string;
}

export interface JobOutputPreview {
  preview: string;
  url: string;
}

export interface Job {
  cluster: Cluster;
  cluster_id: number;
  completed_at: string;
  component: { name: string; type: string };
  created_at: string;
  creator: Creator;
  errors: string;
  failed_at: string;
  html_url: string;
  id: number;
  log_url: string;
  outputs_count: number;
  owner_id: number;
  package: Package;
  package_id: number;
  progress: number;
  runtime_in_seconds: number;
  started_at?: string;
  enqueued_at?: string;
  status: JobStatus;
  updated_at: string;
  url: string;
  outputs: JobOutput[];
  isRemoved?: boolean;
  dynamic_variables?: Variables;
  secret_variables?: Variables;
  variables: Variables;
  responses: any[];
  execution_time_zone: string;
}

export interface JobLog {
  body: string;
  url: string;
  ai_explanation: string;
  error_summary: string;
  action_items: string[];
  openai_extract: string;
  review_status: string;
}
