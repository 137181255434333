import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { selectAccountId, selectUser } from '../../account/store/account.selectors';
import { AppState } from '../../store';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NotifyService } from '../services/notify.service';

@Component({
  selector: 'dialog-template',
  template: `
    <div class="dialog confirm alert text-center feedback-dialog">
      <div class="dialog-text">
        <h3>Help Us Deliver An Even Better Experience!</h3>

        <hr />

        @if (selectedOption === 'Feedback') {
          <div class="sub-title">Send feedback</div>
          <div class="sub-title-description">
            Help us improve Integrate.io! Share feedback, ideas, and how you’d use new features!
            <small
              >You can also speak with live technical support <a class="link" (click)="showIntercom()">here</a></small
            >
          </div>
        }

        @if (selectedOption === 'Feature Request') {
          <div class="sub-title">We want to hear your feature requests</div>
          <div class="sub-title-description">
            Help us improve Integrate.io! Share feedback, ideas, and how you’d use new features!
            <small
              >You can also speak with live technical support <a class="link" (click)="showIntercom()">here</a></small
            >
          </div>
        }

        @if (selectedOption === 'Escalate') {
          <div class="sub-title">Escalate to Integrate.io leadership</div>
          <div class="sub-title-description">
            Got something mission-critical or urgent? This goes directly to our C-level team.
            <small
              >You can also speak with live technical support <a class="link" (click)="showIntercom()">here</a></small
            >
          </div>
        }

        <div class="btn-group btn-group-md btn-group-select">
          <button
            class="btn btn-default"
            [ngClass]="{ active: selectedOption === option }"
            (click)="selectedOption = option"
            *ngFor="let option of options"
          >
            {{ option }}
          </button>
        </div>

        @if (selectedOption === 'Feedback') {
          <textarea
            class="form-control"
            placeholder="Write your feedback here..."
            [(ngModel)]="feedbackContent"
          ></textarea>
        }

        @if (selectedOption === 'Feature Request') {
          <div class="description">
            Click
            <a href="https://integrateio.canny.io/feature-requests" class="link" target="_blank">here</a>
            to see our public-facing customer feature request board. You can upvote the features you’d like to see us
            build next or add new requests for features you don’t see listed yet. Each quarter we dedicated product
            development cycles to requests from these requests.
          </div>
        }

        @if (selectedOption === 'Escalate') {
          <textarea class="form-control" placeholder="This is urgent..." [(ngModel)]="escalateContent"></textarea>
        }
      </div>

      <div class="dialog-btn-container">
        <button class="btn btn-lg btn-default dialog-button" (click)="cancel()">Cancel</button>
        <button
          class="btn btn-lg dialog-button btn-info"
          (click)="send()"
          [disabled]="selectedOption === 'Feature Request'"
        >
          Send
        </button>
      </div>

      <div class="dialog-close" (click)="cancel()"></div>
    </div>
  `,
})
export class FeedbackDialogComponent {
  isLoaded = false;
  userInfo$ = this.store.select(selectUser);
  options = ['Feedback', 'Feature Request', 'Escalate'];
  selectedOption = this.options[0];
  accountId: string;
  feedbackContent: string;
  escalateContent: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private store: Store<AppState>,
    private http: HttpClient,
    private notify: NotifyService,
  ) {}

  ngAfterViewInit() {
    this.store.pipe(selectAccountId).subscribe((account_id) => {
      this.accountId = account_id;
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  send() {
    if (this.selectedOption === 'Feedback') {
      this.http
        .post(`${environment.API_URL}/features/feedback`, { content: this.feedbackContent, account_id: this.accountId })
        .subscribe(() => {
          this.notify.success('Thank you for your feedback!', 'We will get back to you as soon as possible.', 10000);
          this.dialogRef.close(true);
        });
    }

    if (this.selectedOption === 'Escalate') {
      this.http
        .post(`${environment.API_URL}/features/leadership`, {
          content: this.escalateContent,
          account_id: this.accountId,
        })
        .subscribe(() => {
          this.notify.success('Thank you for your feedback!', 'We will get back to you as soon as possible.', 10000);
          this.dialogRef.close(true);
        });
    }
  }

  showIntercom() {
    if (window['Intercom']) {
      window['Intercom']('show');
    }
  }
}
