import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class XpModalService {
  isFullScreen = new BehaviorSubject<boolean>(false);
  localStorageKey = 'IIO_MODAL_FULL_SCREEN';
  modalsData: { [key: string]: boolean } = {};

  constructor() {
    // const modalsData = window.localStorage.getItem(this.localStorageKey);
    // if (modalsData) {
    //   this.modalsData = JSON.parse(modalsData);
    // } else {
    //   window.localStorage.setItem(this.localStorageKey, JSON.stringify({}));
    // }
  }

  getModalData(id: string) {
    return this.modalsData[id];
  }

  setFullScreen(isFullScreen: boolean, id: string) {
    this.isFullScreen.next(isFullScreen);
    this.modalsData[id] = isFullScreen;
    // window.localStorage.setItem(this.localStorageKey, JSON.stringify(this.modalsData));
  }
}
