import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { selectUser } from '../store/account.selectors';
import { Account } from '../account.models';
import { logout } from '../store/account.actions';
import { selectAccounts, selectAccountsIsModalOpenFlag } from '../../settings/store/accounts/accounts.selectors';
import { closeAccountsModal, getAccountsList, openAccountsModal } from '../../settings/store/accounts/accounts.actions';
import { QueryParamsAccountsMenuList } from '../../common/helper/query-params-generic-list.helper';

@Component({
  selector: 'launchpad',
  template: `
    <integrateio-notification></integrateio-notification>
    <div class="launchpad">
      <div class="panel">
        <div class="panel-heading">
          <div class="apps-button"><i class="icon"></i>ETL & Reverse ETL</div>

          <div class="welcome-text" *ngIf="user$ | async as user">
            Welcome, <br />
            {{ user.name || user.email }}
          </div>
          <div class="welcome-description">
            If you have more then one account you can add them and easily switch between.
          </div>
        </div>

        <div class="panel-body">
          <ul class="list-group accounts-list">
            <xp-loader *ngIf="!(accountsList$ | async)"></xp-loader>
            <li class="list-group-item" *ngFor="let account of accountsList$ | async">
              <a class="list-group-item-link" (click)="goToJobs(account)">
                <svg
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="avatar-icon"
                >
                  <g>
                    <circle cx="21" cy="18" r="16" fill="#177AF0" stroke="#ffffff" stroke-width="1" />
                  </g>
                  <text fill="#081D34" text-anchor="middle" x="21" y="24" style="font-size: 18px;">
                    {{ account.name[0].toLowerCase() }}
                  </text>
                </svg>
                <h4 class="account-name">
                  {{ account.name | xpLengthCheck: 24 }}
                </h4>
                <div class="login-link">Log in <i class="fa fa-long-arrow-right"></i></div>
              </a>
            </li>
          </ul>
          <button class="btn btn-link new-account-button" (click)="openNewAccountForm()">
            <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 14 14">
              <polygon style="fill: #177AF0;" points="14,6 8,6 8,0 6,0 6,6 0,6 0,8 6,8 6,14 8,14 8,8 14,8 " />
            </svg>
            <span>{{ 'app.actions.new_account' | translate }}</span>
          </button>
        </div>
        <div class="panel-footer">
          <img class="powered-by" src="assets/img/powered_by_integrate.png" alt="icon" />

          <button class="btn btn-link btn-logout" (click)="logout()">
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.33333 11.5H2.11111C1.81643 11.5 1.53381 11.3829 1.32544 11.1746C1.11706 10.9662 1 10.6836 1 10.3889V2.61111C1 2.31643 1.11706 2.03381 1.32544 1.82544C1.53381 1.61706 1.81643 1.5 2.11111 1.5H4.33333M8.22222 9.27778L11 6.5M11 6.5L8.22222 3.72222M11 6.5H4.33333"
                stroke="#BDC5CF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ 'app.actions.sign_out' | translate }}
          </button>
        </div>
      </div>
      <help-menu></help-menu>
      <xp-modal
        id="account-form-modal"
        [isOpen]="isModalOpen$ | async"
        [closeButtonText]="'account.generic-object.buttons.close' | translate"
        [saveButtonText]="'account.generic-object.buttons.save' | translate"
        [titleText]="'account.generic-object.title' | translate"
        [isSmall]="true"
        (close)="onModalClose()"
      >
        <ng-template>
          <account-form></account-form>
        </ng-template>
      </xp-modal>
    </div>
  `,
})
export class LaunchpadComponent {
  user$ = this.store.select(selectUser);
  accountsList$ = this.store.select(selectAccounts);
  isModalOpen$ = this.store.select(selectAccountsIsModalOpenFlag);
  queryParams = { ...QueryParamsAccountsMenuList };

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {
    this.store.dispatch(getAccountsList({ params: { ...this.queryParams }, getAll: true }));
  }

  goToJobs(account: Account) {
    this.router.navigate([`/${account.account_id}/jobs`]);
  }

  onModalClose(): void {
    this.store.dispatch(closeAccountsModal());
  }

  logout() {
    this.store.dispatch(logout({ shouldRedirect: true }));
  }

  openNewAccountForm() {
    this.store.dispatch(openAccountsModal());
  }
}
