import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GenericListType, LoadMoreQuery } from '../../common/components/lists/generic-list.component';
import { AppState } from '../../store';
import {
  closePackagesModal,
  closePackageVersionsModal,
  closePackageWorkspacesModal,
  getPackagesList,
  loadMorePackagesList,
  openPackagesModal,
  openPackageVersionsModal,
  openPackageWorkspacesModal,
  searchPackagesList,
} from '../store/packages.actions';
import {
  selectAreAllPackagesLoadedFlag,
  selectPackages,
  selectPackagesErrorFlag,
  selectPackagesIsModalOpenFlag,
  selectPackagesIsVersionModalOpenFlag,
  selectPackagesIsWorkspaceModalOpenFlag,
  selectPackagesLoadingFlag,
} from '../store/packages.selectors';
import { Package } from '../package.models';
import { AssistedSearchPackagesConfiguratorService } from '../../common/services/assisted-search/assisted-search-packages-configurator.service';
import { SearchConfig } from '../../common/services/assisted-search/search-config.model';
import { selectWorkspacesFeatureFlag } from '../../account/store/account.selectors';
import { selectJobsIsModalOpenFlag } from '../../jobs/store/jobs.selectors';
import { closeJobsModal, openJobsModal } from '../../jobs/store/jobs.actions';
import { Job } from '../../jobs/jobs.models';
import { selectClustersIsModalOpenFlag, selectLastlyCreatedCluster } from '../../clusters/store/clusters.selectors';
import { Cluster, ClusterTypes } from '../../clusters/clusters.models';
import { closeClustersModal } from '../../clusters/store/clusters.actions';
import { loadSelectPickerItems } from '../../common/store/select-picker.actions';
import {
  queryParamsMap,
  SelectPickerTypes,
} from '../../common/components/forms/select-picker/select-picker-types.enum';
import { QueryParamsPackagesList } from '../../common/helper/query-params-generic-list.helper';
import { IdleTimeValues } from '../../clusters/services/idle-times.service';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'packages-list',
  template: `
    <generic-list
      [type]="type"
      [items]="packages$ | async"
      [isLoading]="packagesLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openCreateModal()"
      [newBtnText]="'package.generic-object.buttons.new' | translate"
      [emptyTitle]="'package.generic-object.empty.title' | translate"
      [emptyDescription]="'package.generic-object.empty.description' | translate"
      (loadMore)="loadMorePackages($event)"
      [limit]="queryParams.limit"
      [allItemsLoaded]="areAllPackagesLoaded$ | async"
      [searchPlaceholder]="'package.generic-object.placeholders.search' | translate"
      [searchConfig]="searchConfig"
      [searchQueryFromUrl]="searchQueryFromUrl"
      [hasSearch]="true"
      [isError]="packagesError$ | async"
      (searchSubmit)="onSearchSubmit($event)"
    >
      <ng-template templateName="listItem" let-item>
        <package-list-item
          [item]="item"
          [searchValue]="searchValue"
          (edit)="editPackage($event)"
          (editVersion)="editPackageVersion($event)"
          (editWorkspace)="editPackageWorkspace($event)"
          (runJob)="openJobCreateModal($event)"
        ></package-list-item>
      </ng-template>

      <ng-template templateName="headers">
        <div class="generic-list-headers packages">
          <div class="name">
            <span (click)="changeOrder('name')">
              <span class="sorting">{{ 'generic-list.packages.name' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'name',
                  'fa-chevron-down': queryParams.direction === 'desc' && queryParams.sort === 'name',
                  'fa-chevron-up': queryParams.direction === 'asc' && queryParams.sort === 'name',
                }"
              ></i>
            </span>
          </div>
          <div *ngIf="(isWorkspacesFeatureEnabled$ | async) && !isWorkspacesItemView" class="owner">
            {{ 'generic-list.packages.workspace' | translate }}
          </div>
          <div class="owner">{{ 'generic-list.packages.owner' | translate }}</div>
          <div class="date">
            <span ng-click="changeOrder($event, 'updated')">
              <span class="sorting">{{ 'generic-list.packages.date' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'updated',
                  'fa-chevron-down': queryParams.direction === 'desc' && queryParams.sort === 'updated',
                  'fa-chevron-up': queryParams.direction === 'asc' && queryParams.sort === 'updated',
                }"
              ></i>
            </span>
          </div>
          <div class="run-job">{{ 'generic-list.packages.run_job' | translate }}</div>
        </div>
      </ng-template>
    </generic-list>
    <xp-modal
      id="package-form-modal"
      [isOpen]="isModalOpen$ | async"
      [closeButtonText]="'package.generic-object.buttons.close' | translate"
      [saveButtonText]="'package.generic-object.buttons.save' | translate"
      [titleText]="'package.generic-object.title' | translate"
      (close)="onModalClose()"
      [isSmall]="true"
    >
      <ng-template>
        <package-form [item]="formItem"></package-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="package-version-form-modal"
      [isOpen]="isVersionsModalOpen$ | async"
      [closeButtonText]="'package.generic-object.buttons.close' | translate"
      [saveButtonText]="'package.generic-object.buttons.save' | translate"
      [titleText]="'package.generic-object.title' | translate"
      (close)="onVersionModalClose()"
      [isSmall]="true"
    >
      <ng-template>
        <package-version-form [item]="formItem"></package-version-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="package-workspace-form-modal"
      [isOpen]="isWorkspacesModalOpen$ | async"
      [closeButtonText]="'package.generic-object.buttons.close' | translate"
      [saveButtonText]="'package.generic-object.buttons.save' | translate"
      [titleText]="'package.generic-object.title' | translate"
      (close)="onWorkspaceModalClose()"
      [isSmall]="true"
    >
      <ng-template>
        <package-workspace-form [item]="formItem"></package-workspace-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="job-form-modal"
      [isOpen]="isJobModalOpen$ | async"
      [closeButtonText]="'package.generic-object.buttons.close' | translate"
      [saveButtonText]="'job.generic-object.buttons.save' | translate"
      [titleText]="'job.generic-object.title' | translate"
      (close)="onJobModalClose()"
    >
      <ng-template>
        <job-form [item]="jobFormItem" [disableModalClose]="true"></job-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="cluster-form-modal"
      [isOpen]="isClusterModalOpen$ | async"
      [closeButtonText]="'cluster.generic-object.buttons.close' | translate"
      [saveButtonText]="'cluster.generic-object.buttons.save' | translate"
      [titleText]="'cluster.generic-object.title' | translate"
      (close)="onClusterModalClose()"
      [isSmall]="true"
    >
      <ng-template>
        <cluster-form [item]="clusterFormItem" [disableModalClose]="true"></cluster-form>
      </ng-template>
    </xp-modal>
  `,
})
export class PackagesListComponent implements AfterViewInit, OnInit, OnDestroy {
  type = GenericListType.packages;
  packagesLoading$ = this.store.select(selectPackagesLoadingFlag);
  packagesError$ = this.store.select(selectPackagesErrorFlag);
  isModalOpen$ = this.store.select(selectPackagesIsModalOpenFlag);
  isVersionsModalOpen$ = this.store.select(selectPackagesIsVersionModalOpenFlag);
  isWorkspacesModalOpen$ = this.store.select(selectPackagesIsWorkspaceModalOpenFlag);
  isJobModalOpen$ = this.store.select(selectJobsIsModalOpenFlag);
  isClusterModalOpen$ = this.store.select(selectClustersIsModalOpenFlag);
  packages$ = this.store.select(selectPackages);
  areAllPackagesLoaded$ = this.store.select(selectAreAllPackagesLoadedFlag);
  isWorkspacesItemView = false;
  isWorkspacesFeatureEnabled$ = this.store.select(selectWorkspacesFeatureFlag);
  queryParams = QueryParamsPackagesList;
  formItem: Partial<Package> = {};
  searchQueryFromUrl = '';
  searchConfig: SearchConfig = {};
  jobFormItem: Partial<Job> = {};
  clusterFormItem: Partial<Cluster> = {
    name: '',
    type: ClusterTypes.sandbox,
    nodes: 1,
    terminate_on_idle: true,
    time_to_idle: IdleTimeValues.ThirtyMinutes,
    reuse_cluster_strategy: 'any',
  };
  clusterSubscription: Subscription;
  searchQuery = '';
  searchValue = '';

  constructor(
    private store: Store<AppState>,
    private assistedSearchPackagesConfigurator: AssistedSearchPackagesConfiguratorService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const query = this.route.snapshot.queryParams.query;

    this.isWorkspacesItemView = this.router.url.includes('/workspaces');

    if (this.isWorkspacesItemView) {
      const { workspace_id } = this.route.snapshot.params;
      this.queryParams = {
        ...this.queryParams,
        workspace_id: workspace_id,
      };
    }

    if (!query) {
      this.store.dispatch(getPackagesList({ params: this.queryParams }));
    }

    this.clusterSubscription = this.store.select(selectLastlyCreatedCluster).subscribe((cluster) => {
      if (cluster) {
        this.store.dispatch(
          loadSelectPickerItems({
            id: 'cluster-picker-job-form',
            itemType: SelectPickerTypes.cluster,
            params: queryParamsMap[SelectPickerTypes.cluster],
          }),
        );
        this.jobFormItem = {
          ...this.jobFormItem,
          cluster_id: cluster.id,
        };
      }
    });

    this.searchQueryFromUrl = query || '';

    this.assistedSearchPackagesConfigurator.getConfig$().subscribe((config) => {
      this.searchConfig = config;
    });
  }

  ngAfterViewInit() {
    if (this.router.url.includes('/new')) {
      this.openCreateModal();
    }
  }

  openJobCreateModal(packageItem: Package): void {
    this.jobFormItem = {
      package: packageItem,
    };
    this.store.dispatch(openJobsModal());
  }

  changeOrder(field: string) {
    let { direction } = this.queryParams;
    if (field === this.queryParams.sort) {
      direction = direction === 'asc' ? 'desc' : 'asc';
    }
    this.queryParams = { ...this.queryParams, sort: field, direction };
    this.store.dispatch(getPackagesList({ params: this.queryParams }));
  }

  editPackage(item: Package) {
    this.formItem = { ...item };
    this.store.dispatch(openPackagesModal());
  }

  editPackageVersion(item: Package) {
    this.formItem = { ...item };
    this.store.dispatch(openPackageVersionsModal());
  }

  editPackageWorkspace(item: Package) {
    this.formItem = { ...item };
    this.store.dispatch(openPackageWorkspacesModal());
  }

  openCreateModal(): void {
    this.formItem = {};
    this.router.navigate(['new'], { relativeTo: this.route });
    this.store.dispatch(openPackagesModal());
  }

  onModalClose(): void {
    const queryParams = this.searchQuery ? { query: this.searchQuery } : {};
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    this.store.dispatch(closePackagesModal());
  }

  onVersionModalClose(): void {
    const queryParams = this.searchQuery ? { query: this.searchQuery } : {};
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    this.store.dispatch(closePackageVersionsModal());
  }

  onWorkspaceModalClose(): void {
    const queryParams = this.searchQuery ? { query: this.searchQuery } : {};
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    this.store.dispatch(closePackageWorkspacesModal());
  }

  onJobModalClose(): void {
    const queryParams = this.searchQuery ? { query: this.searchQuery } : {};
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    this.store.dispatch(closeJobsModal());
  }

  onClusterModalClose(): void {
    this.store.dispatch(closeClustersModal());
  }

  loadMorePackages(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMorePackagesList({ params }));
  }

  onSearchSubmit({ query, text }) {
    const params = { ...this.queryParams, q: query };
    this.queryParams = params;
    this.searchQuery = query;

    if (!query) {
      this.store.dispatch(getPackagesList({ params: this.queryParams }));
    } else {
      this.store.dispatch(searchPackagesList({ params }));
    }

    this.packagesLoading$
      .pipe(
        filter((isLoading) => !isLoading),
        first(),
      )
      .subscribe(() => {
        this.searchValue = text || '';
      });
  }

  ngOnDestroy() {
    if (this.clusterSubscription) {
      this.clusterSubscription.unsubscribe();
    }
  }
}
