import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GenericListType, LoadMoreQuery } from '../../common/components/lists/generic-list.component';
import { AppState } from '../../store';
import {
  closeChildJobDetailsModal,
  closeJobDetailsModal,
  closeJobsModal,
  getJobsList,
  loadMoreJobsList,
  openChildJobDetailsModal,
  openJobDetailsModal,
  openJobsModal,
  searchJobsList,
} from '../store/jobs.actions';
import {
  selectJobs,
  selectJobIsDetailsModalOpenFlag,
  selectJobsIsModalOpenFlag,
  selectJobsLoadingFlag,
  selectChildJobIsDetailsModalOpenFlag,
  selectJobsErrorFlag,
  selectAllJobsLoadedFlag,
} from '../store/jobs.selectors';
import { AssistedSearchJobsConfiguratorService } from '../../common/services/assisted-search/assisted-search-jobs-configurator.service';
import { SearchConfig } from '../../common/services/assisted-search/search-config.model';
import { Job } from '../jobs.models';
import { selectClustersIsModalOpenFlag } from '../../clusters/store/clusters.selectors';
import { closeClustersModal } from '../../clusters/store/clusters.actions';
import { Cluster, ClusterTypes } from '../../clusters/clusters.models';
import { QueryParamsJobsList } from '../../common/helper/query-params-generic-list.helper';
import { IdleTimeValues } from '../../clusters/services/idle-times.service';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'jobs-list',
  template: `
    <generic-list
      [type]="type"
      [items]="jobs$ | async"
      [isLoading]="jobsLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openJobCreateModal()"
      [newBtnText]="'job.generic-object.buttons.new' | translate"
      [emptyTitle]="'job.generic-object.empty.title' | translate"
      [emptyDescription]="'job.generic-object.empty.description' | translate"
      [allItemsLoaded]="areAllJobsLoaded$ | async"
      [limit]="queryParams.limit"
      (loadMore)="loadMoreJobs($event)"
      [searchPlaceholder]="'job.generic-object.placeholders.search' | translate"
      [searchConfig]="searchConfig"
      [searchQueryFromUrl]="searchQueryFromUrl"
      [hasSearch]="true"
      [isError]="jobsError$ | async"
      (searchSubmit)="onSearchSubmit($event)"
    >
      <ng-template templateName="listItem" let-item>
        <job-list-item
          [item]="item"
          [searchValue]="searchValue"
          (openJobDetails)="openJobDetailsModal($event)"
        ></job-list-item>
      </ng-template>
      <ng-template templateName="headers">
        <div class="generic-list-headers jobs">
          <div class="job">{{ 'generic-list.jobs.id' | translate }}</div>
          <div class="package">{{ 'generic-list.jobs.package' | translate }}</div>
          <div class="cluster">{{ 'generic-list.jobs.cluster' | translate }}</div>
          <div class="runtime">
            {{ 'generic-list.jobs.runtime' | translate }}
          </div>
          <div class="status">{{ 'generic-list.jobs.status' | translate }}</div>
          <div class="details"></div>
        </div>
      </ng-template>
    </generic-list>
    <xp-modal
      id="cluster-form-modal"
      [isOpen]="isClusterModalOpen$ | async"
      [closeButtonText]="'cluster.generic-object.buttons.close' | translate"
      [saveButtonText]="'cluster.generic-object.buttons.save' | translate"
      [titleText]="'cluster.generic-object.title' | translate"
      (close)="onClusterModalClose()"
    >
      <ng-template>
        <cluster-form
          [item]="clusterFormItem"
          [isInJobsView]="true"
          (clusterCreated)="onClusterCreated($event)"
        ></cluster-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="job-form-modal"
      [isOpen]="isJobModalOpen$ | async"
      [closeButtonText]="'job.generic-object.buttons.close' | translate"
      [saveButtonText]="'job.generic-object.buttons.save' | translate"
      [titleText]="'job.generic-object.title' | translate"
      (close)="onJobModalClose()"
    >
      <ng-template>
        <job-form [item]="formItem" [createdCluster]="createdCluster"></job-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="job-details-modal"
      [isOpen]="isJobDetailsModalOpen$ | async"
      [closeButtonText]="'job.generic-list.item.modals.output.close' | translate"
      [saveButtonText]="'job.generic-object.buttons.save' | translate"
      [titleText]="'job.generic-object.title' | translate"
      (close)="onJobDetailsModalClose()"
    >
      <ng-template>
        <job-details [item]="jobDetailsItem" (childOpen)="openChildJobDetailsModal($event)"></job-details>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="job-details-modal-child"
      [isOpen]="isChildJobDetailsModalOpen$ | async"
      [closeButtonText]="'job.generic-list.item.modals.output.close_details' | translate"
      [saveButtonText]="'job.generic-object.buttons.save' | translate"
      [titleText]="'job.generic-object.title' | translate"
      [isBack]="true"
      (close)="onChildJobDetailsModalClose()"
    >
      <ng-template>
        <job-details [item]="childJobDetailsItem"></job-details>
      </ng-template>
    </xp-modal>
  `,
})
export class JobsListComponent implements AfterViewInit, OnInit, OnDestroy {
  type = GenericListType.jobs;
  jobsLoading$ = this.store.select(selectJobsLoadingFlag);
  jobsError$ = this.store.select(selectJobsErrorFlag);
  isJobModalOpen$ = this.store.select(selectJobsIsModalOpenFlag);
  isJobDetailsModalOpen$ = this.store.select(selectJobIsDetailsModalOpenFlag);
  isClusterModalOpen$ = this.store.select(selectClustersIsModalOpenFlag);
  isChildJobDetailsModalOpen$ = this.store.select(selectChildJobIsDetailsModalOpenFlag);
  areAllJobsLoaded$ = this.store.select(selectAllJobsLoadedFlag);
  jobs$ = this.store.select(selectJobs);
  queryParams = QueryParamsJobsList;
  formItem: Partial<Job> = {};
  searchQueryFromUrl = '';
  searchConfig: SearchConfig = {};
  jobDetailsItem: Partial<Job> = {};
  childJobDetailsItem: Partial<Job> = {};
  jobSubscription: Subscription;
  searchSubscription: Subscription;
  clusterFormItem: Partial<Cluster> = {
    name: '',
    type: ClusterTypes.sandbox,
    nodes: 1,
    terminate_on_idle: true,
    time_to_idle: IdleTimeValues.ThirtyMinutes,
    reuse_cluster_strategy: 'any',
  };
  createdCluster: Cluster = null;
  searchQuery = '';
  searchValue = '';

  constructor(
    private store: Store<AppState>,
    private assistedSearchJobsConfigurator: AssistedSearchJobsConfiguratorService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const query = this.route.snapshot.queryParams.query;

    this.searchQueryFromUrl = query || '';

    this.searchSubscription = this.assistedSearchJobsConfigurator.getConfig$().subscribe((config) => {
      this.searchConfig = config;
    });

    if (!query) {
      this.store.dispatch(getJobsList({ params: this.queryParams }));
    }
  }

  ngAfterViewInit() {
    if (this.router.url.includes('/jobs/new')) {
      this.openJobCreateModal();
      return;
    }

    if (this.router.url.includes('/children')) {
      this.openJobDetailsModal({ id: this.route.firstChild.snapshot.params.job_id });
      this.openChildJobDetailsModal({ id: this.route.firstChild.firstChild.snapshot.params.child_id });
    }

    if (this.router.url.match(/\/jobs\/\w+/) && !this.router.url.includes('/children')) {
      const { job_id } = this.route.firstChild?.snapshot.params || {};
      this.openJobDetailsModal({ id: job_id });
    }
  }

  openJobCreateModal(): void {
    this.router.navigate(['new'], { relativeTo: this.route });
    this.store.dispatch(openJobsModal());
  }

  openJobDetailsModal(job: Partial<Job>): void {
    this.jobDetailsItem = job;
    this.router.navigate([`${job.id}`], { relativeTo: this.route });
    this.store.dispatch(openJobDetailsModal());
  }

  openChildJobDetailsModal(job: Partial<Job>): void {
    this.childJobDetailsItem = job;
    this.router.navigate([`${this.jobDetailsItem.id}/children/${job.id}`], { relativeTo: this.route });
    this.store.dispatch(openChildJobDetailsModal());
  }

  onJobModalClose(): void {
    const queryParams = this.searchQuery ? { query: this.searchQuery } : {};
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    this.store.dispatch(closeJobsModal());
  }

  onClusterCreated(cluster: Cluster): void {
    this.createdCluster = cluster;
  }

  onClusterModalClose(): void {
    this.store.dispatch(closeClustersModal());
  }

  onJobDetailsModalClose(): void {
    const queryParams = this.searchQuery ? { query: this.searchQuery } : {};
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    this.store.dispatch(closeJobDetailsModal());
  }

  onChildJobDetailsModalClose(): void {
    if (this.route.firstChild?.snapshot?.params?.job_id) {
      this.router.navigate([`${this.route.firstChild.snapshot.params.job_id}`], { relativeTo: this.route });
    }
    this.store.dispatch(closeChildJobDetailsModal());
  }

  loadMoreJobs(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreJobsList({ params }));
  }

  onSearchSubmit({ query, text }) {
    const params = { ...this.queryParams, q: query };
    this.queryParams = params;
    this.searchQuery = query;

    if (!query) {
      this.store.dispatch(getJobsList({ params: this.queryParams }));
    } else {
      this.store.dispatch(searchJobsList({ params }));
    }

    this.jobsLoading$
      .pipe(
        filter((isLoading) => !isLoading),
        first(),
      )
      .subscribe(() => {
        this.searchValue = text || '';
      });
  }

  ngOnDestroy() {
    if (this.jobSubscription) {
      this.jobSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
}
