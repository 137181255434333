import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { Schema } from '../../../package.models';

@Component({
  selector: 'schema-mapping-netsuite-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['field_name', 'column_name']"
      duplicationValidationProp="column_name"
      duplicationValidationPropName="Destination column name"
    >
      <ng-template templateName="field_name" let-item>
        <xp-field-picker
          [value]="item.record.field_name"
          [index]="item.index"
          [schema]="schemas[0]"
          [fields]="(schemas[0] || {}).fields || []"
          propName="field_name"
          (fieldChange)="onFieldChange($event, item.record, 'field_name')"
          class="fields-collection-editor"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="field_name-header" let-item>
        <span>{{ 'schema-mapping-netsuite.headers.input-field' | translate }}</span>
      </ng-template>
      <ng-template templateName="column_name" let-item>
        <xp-field-picker
          [value]="item.record.column_name"
          [index]="item.index"
          [schema]="schemas[1]"
          [fields]="(schemas[1] || {}).fields || []"
          [isDuplicateError]="item.record.isDuplicateError"
          propName="column_name"
          (fieldChange)="onFieldChange($event, item.record, 'column_name')"
          class="fields-collection-editor"
          [ngClass]="{ readonly: item.record.required }"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="column_name-header" let-item>
        <span>{{ 'schema-mapping-netsuite.headers.destination-field' | translate }}</span>
      </ng-template>
    </xp-fields-collection>
  `,
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchemaMappingNetsuiteCollectionComponent implements OnInit, OnChanges {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() schemas: Schema[];
  @Input() fields: DesignerSchemaFieldI[];
  @Input() operationType: string;
  @Input() idField: string;
  @Input() objectName: string;
  @Input() connectionType: string = 'netsuitesoap';
  @Input() connectionId: number | string;
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  oldIdField = '';

  collectionSettings: CollectionSettings;

  recordsCopy: DesignerSchemaFieldI[] = [];

  constructor() {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      itemsPerPage: 10,
      emptyRecord: {
        field_name: '',
        column_name: '',
        field_type: '',
        FC_pristine: true,
      },
      parentSchemas: this.schemas,
      autoFillFns: [
        {
          func: this.autoFill.bind(this),
          text: 'Auto-fill',
        },
      ],
    };

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.operationType && this.fields) {
      this.onOperationTypeChange(changes.operationType.currentValue, changes.operationType.previousValue);
    }

    if (changes.fields && changes.fields.currentValue) {
      this.fillRecords();
      this.fillParentSchemas();
    }

    if (changes.idField) {
      const newField = changes.idField.currentValue;
      const oldField = changes.idField.previousValue;

      if (!newField) {
        this.oldIdField = oldField;
      }
    }
  }

  onOperationTypeChange(newOperationType: string, oldOperationType: string) {
    this.fillParentSchemas();
    if (newOperationType !== oldOperationType) {
      if (oldOperationType === 'delete') {
        this.recordsCopy[0].required = false;
      }

      if (newOperationType === 'delete') {
        const field = this.fields.find((item) => item.type === 'ID');

        if (field) {
          let parent_field_name = '';
          if (this.schemas[0] && this.schemas[0].fields[0]) {
            parent_field_name = this.schemas[0].fields[0].name;
          }
          const record = {
            required: true,
            addDisabled: true,
            field_name: parent_field_name,
            column_name: field.name,
            relationship_name: '',
            field_type: field.type,
          };
          this.recordsCopy = [record];
        }
      }

      if (newOperationType === 'insert') {
        this.recordsCopy = this.recordsCopy.filter((item) => item.column_name !== this.oldIdField);
      }

      if (newOperationType === 'upsert' || newOperationType === 'update') {
        setTimeout(() => {
          const record = this.recordsCopy.find((item) => item.column_name === this.idField);
          if (!record) {
            this.addFieldByName(this.idField, true, true);
          } else {
            this.recordsCopy = this.recordsCopy.map((item) =>
              item.column_name === this.idField ? { ...item, required: true } : item,
            );
            this.onRecordChange({ records: this.recordsCopy });
          }
        });
      }
    }
  }

  fillRecords() {
    const requiredFields = {};
    this.recordsCopy = this.recordsCopy.map((record) => {
      let required;
      if (!requiredFields[record.column_name]) {
        if (this.operationType === 'upsert' || this.operationType === 'update') {
          if (!requiredFields[record.column_name] && record.column_name === this.idField) {
            required = true;
            requiredFields[record.column_name] = true;
          }
        } else if (this.operationType === 'delete') {
          required = true;
          requiredFields[record.column_name] = true;
        } else {
          const field = this.fields.find((item) => item.name === record.column_name);
          if (field && field.is_required) {
            required = true;
            requiredFields[record.column_name] = true;
          } else {
            required = false;
          }
        }
      }
      return {
        ...record,
        required,
      };
    });
  }

  fillParentSchemas() {
    const destinationSchema = {} as Schema;
    destinationSchema.id = `netsuite_fields_${this.objectName}`;
    destinationSchema.name = `netsuite_fields_${this.objectName}`;

    const objectsSchema = {} as Schema;
    objectsSchema.id = `objects_fields_${this.objectName}`;
    objectsSchema.name = `objects_fields_${this.objectName}`;

    this.fields.forEach((field) => {
      destinationSchema.fields = [
        ...(destinationSchema.fields || []),
        {
          name: field.name,
        },
      ];
      if (field && field.is_required) {
        const isFieldExisting =
          this.recordsCopy.find((item) => item.column_name === field.name) ||
          destinationSchema.fields.find((item) => item.column_name === field.name);
        if (!isFieldExisting) {
          if (this.operationType === 'insert') {
            let parent_field_name = '';
            if (this.schemas[0] && this.schemas[0].fields[0]) {
              parent_field_name = this.schemas[0].fields[0].name;
            }
            this.recordsCopy = [
              ...this.recordsCopy,
              {
                required: true,
                field_name: parent_field_name,
                column_name: field.name,
                field_type: field.type,
                id: uuidv4(),
              },
            ];
          }
        } else {
          isFieldExisting.required = this.isRequired();
        }
      }
    });

    this.schemas = [this.schemas[0], destinationSchema, objectsSchema];
  }

  setReference(value: string) {
    const record = this.recordsCopy.find((item) => item.column_name === value);
    if (record) {
      const field = this.fields.find((item) => item.name === value);
      if (field) {
        record.field_type = field.data_type;
      }
    }
  }

  autoFill() {
    const records: DesignerSchemaFieldI[] = [];
    this.schemas[1].fields.forEach((field) => {
      const fieldDestination = this.schemas[0].fields.find((item) => item.name === field.name);
      const fieldToAdd = this.fields.find((item) => item.name === field.name);
      if (fieldDestination || (this.operationType === 'insert' && fieldToAdd && fieldToAdd.is_required)) {
        let field_name;
        if (fieldDestination) {
          field_name = fieldToAdd.name;
        } else {
          field_name = this.schemas[0] && this.schemas[0].fields[0] ? this.schemas[0].fields[0].name : '';
        }
        const record: DesignerSchemaFieldI = {
          field_name,
          column_name: fieldToAdd.name,
          field_type: fieldToAdd.type,
          id: uuidv4(),
        };

        record.required = this.operationType === 'insert' && fieldToAdd.is_required && this.isRequired();
        records.push(record);
      }
    });

    if (!records.length) {
      return this.recordsCopy;
    }
    return records;
  }

  isRequired() {
    return this.operationType !== 'upsert' && this.operationType !== 'update';
  }

  addFieldByName(fieldName, addFirst, isRequired) {
    const field = this.fields.find((item) => item.name === fieldName);
    let parent_field_name = '';
    if (this.schemas[0] && this.schemas[0].fields[0]) {
      parent_field_name = this.schemas[0].fields[0].name;
    }
    if (field) {
      const newRecord = {
        required: isRequired,
        field_name: parent_field_name,
        column_name: field.name,
        field_type: field.type,
        id: uuidv4(),
      };
      if (addFirst) {
        this.recordsCopy = [newRecord, ...this.recordsCopy];
      } else {
        this.recordsCopy = [...this.recordsCopy, newRecord];
      }
    }
  }

  onRecordChange({ records }) {
    if (!this.fields) {
      return;
    }
    this.recordsCopy = records;
    const columnMappings = records.map((record) => ({
      column_name: record.column_name,
      field_name: record.field_name,
      field_type: record.field_type,
    }));

    this.recordsChange.emit(columnMappings);
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    if (!this.fields || value === undefined || record[prop] === value) {
      return;
    }
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    if (prop === 'column_name') {
      this.setReference(value);
    }

    this.onRecordChange({
      records: newRecords,
    });
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }
}
