import {
  Analytics4SourceComponentData,
  AnalyticsSourceComponentData,
  AllComponentData,
  BingAdsSourceComponentData,
  CloudStorageSourceComponentData,
  Component,
  Schema,
  SortComponentData,
} from '../package.models';
import { ComponentTypeItem } from '../../constants/component_types';
import { getComponentSchema } from './component-schema.helpers';
import {
  ComponentPreviewerData,
  findComponent,
  getComponentType,
  getDataFromComponent,
  updateComponent,
} from './components.helpers';
import { COMPONENT_TYPE } from '../../constants/component_type';
import { Package } from '../../packages/package.models';
import { SORT_DIRECTION_DESC, SORT_DIRECTION_ASC } from '../../constants/sort_direction';

const updateComponentMetaFields: {
  [key: string]: (component: Component, data: AllComponentData) => Component;
} = {
  [COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT]: (
    componentItem: Component,
    componentData: AnalyticsSourceComponentData,
  ) => {
    const fields = [
      {
        name: componentData.profile_id_field_alias,
        alias: componentData.profile_id_field_alias,
        data_type: 'string',
      },
      {
        name: componentData.account_name_field_alias,
        alias: componentData.account_name_field_alias,
        data_type: 'string',
      },
      {
        name: componentData.property_name_field_alias,
        alias: componentData.property_name_field_alias,
        data_type: 'string',
      },
      {
        name: componentData.profile_name_field_alias,
        alias: componentData.profile_name_field_alias,
        data_type: 'string',
      },
    ];

    return {
      [COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT]: {
        ...componentItem[COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT],
        schema: {
          ...componentItem[COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT].schema,
          fields: [...componentItem[COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT].schema.fields, ...fields],
        },
      },
    } as any;
  },

  [COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT]: (
    componentItem: Component,
    componentData: Analytics4SourceComponentData,
  ) => {
    const fields = [
      {
        name: componentData.property_id_field_alias,
        alias: componentData.property_id_field_alias,
        data_type: 'string',
      },
      {
        name: componentData.account_name_field_alias,
        alias: componentData.account_name_field_alias,
        data_type: 'string',
      },
      {
        name: componentData.property_name_field_alias,
        alias: componentData.property_name_field_alias,
        data_type: 'string',
      },
      {
        name: componentData.account_id_field_alias,
        alias: componentData.account_id_field_alias,
        data_type: 'string',
      },
    ];

    return {
      [COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT]: {
        ...componentItem[COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT],
        schema: {
          ...componentItem[COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT].schema,
          fields: [...componentItem[COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT].schema.fields, ...fields],
        },
      },
    } as any;
  },

  [COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT]: (componentItem: Component, componentData: BingAdsSourceComponentData) => {
    const fields = [
      {
        name: componentData.time_period_date_field_alias,
        alias: componentData.time_period_date_field_alias,
        data_type: 'string',
      },
    ];

    return {
      [COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT]: {
        ...componentItem[COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT],
        schema: {
          ...componentItem[COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT].schema,
          fields: [...componentItem[COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT].schema.fields, ...fields],
        },
      },
    } as any;
  },

  [COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT]: (
    componentItem: Component,
    componentData: CloudStorageSourceComponentData,
  ) => {
    let fields = [];

    if (componentData.source_path_field_alias) {
      fields = [
        {
          name: componentData.source_path_field_alias,
          alias: componentData.source_path_field_alias,
          data_type: 'string',
        },
      ];
    }

    return {
      [COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT]: {
        ...componentItem[COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT],
        schema: {
          ...componentItem[COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT].schema,
          fields: [...componentItem[COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT].schema.fields, ...fields],
        },
      },
    } as any;
  },
  [COMPONENT_TYPE.SORT_COMPONENT]: (componentItem: Component, componentData: SortComponentData) => {
    /*
     * For component previewer cloud storage destination component with enforce_single_file=true is used,
     * which uses grouping in the PIG script, which changes the order of the records from the previous lines
     * To compensate for this behavior and maintain the expected sort order in the preview:
     * - ASC becomes DESC
     * - DESC becomes ASC
     * This inversion ensures the final output matches the user's intended sort direction.
     */
    const fields = (componentItem[COMPONENT_TYPE.SORT_COMPONENT].fields || []).map((field) => ({
      ...field,
      order_type: field.order_type === SORT_DIRECTION_ASC ? SORT_DIRECTION_DESC : SORT_DIRECTION_ASC,
    }));
    return {
      [COMPONENT_TYPE.SORT_COMPONENT]: {
        ...componentItem[COMPONENT_TYPE.SORT_COMPONENT],
        fields,
      },
    } as any;
  },
};

function componentIsIncluded(component: Component, componentsArray: Component[]): boolean {
  return !!componentsArray.find((item) => getDataFromComponent(item).id === getDataFromComponent(component).id);
}

export function getPartialGraphForComponentPreviewer(endComponentId: string) {
  return ({ components, edges }: Partial<Package>, currentComponent?: ComponentTypeItem): ComponentPreviewerData => {
    const currentComponentEdges = (edges || []).filter((edge) => edge.target === endComponentId);

    const foundComponent = findComponent(components, endComponentId);

    if (!foundComponent) {
      return {
        components: [],
        edges: [],
        destination_schema: {} as Schema,
      };
    }
    const component = currentComponent ? updateComponent(foundComponent, currentComponent) : foundComponent;

    const jsonMap: ComponentPreviewerData = {
      components: [component],
      edges: [],
      destination_schema: {} as Schema,
    };

    let edgesToProcess = [...currentComponentEdges];
    while (edgesToProcess.length) {
      const currentEdge = edgesToProcess.shift();
      const componentToAdd = findComponent(components, currentEdge.source);
      if (!componentIsIncluded(componentToAdd, jsonMap.components)) {
        jsonMap.components = [...jsonMap.components, componentToAdd];
      }
      if (!jsonMap.edges.find((edge) => edge.id === currentEdge.id)) {
        jsonMap.edges = [...jsonMap.edges, currentEdge];
      }
      // eslint-disable-next-line no-loop-func
      const nextEdges = edges.filter((edgeItem) => edgeItem.target === currentEdge.source);
      if (nextEdges.length) {
        edgesToProcess = [...edgesToProcess, ...nextEdges];
      }
    }

    jsonMap.components = jsonMap.components.map((componentItem) => {
      const componentType = getComponentType(componentItem);
      const componentData = getDataFromComponent(componentItem);
      const generator = updateComponentMetaFields[componentType];

      if (generator) {
        return generator(componentItem, componentData);
      }

      return componentItem;
    });

    jsonMap.destination_schema = getComponentSchema(component, components, edges);

    return jsonMap;
  };
}
