import { AfterViewInit, Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericListType, LoadMoreQuery } from '../../../common/components/lists/generic-list.component';
import { AppState } from '../../../store';
import { closeKeysModal, getKeysList, loadMoreKeysList, openKeysModal } from '../../store/keys/keys.actions';
import {
  selectAreAllKeysLoadedFlag,
  selectKeys,
  selectKeysErrorFlag,
  selectKeysIsModalOpenFlag,
  selectKeysLoadingFlag,
} from '../../store/keys/keys.selectors';
import { QueryParamsKeysList } from '../../../common/helper/query-params-generic-list.helper';

@Component({
  selector: 'keys-list',
  template: `
    <generic-list
      [type]="type"
      [items]="keys$ | async"
      [isLoading]="keysLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openCreateModal()"
      [newBtnText]="'ssh_key.generic-object.buttons.new' | translate"
      [emptyTitle]="'ssh_key.generic-object.empty.title' | translate"
      [emptyDescription]="'ssh_key.generic-object.empty.description' | translate"
      (loadMore)="loadMoreKeys($event)"
      [allItemsLoaded]="areAllKeysLoaded$ | async"
      [limit]="queryParams.limit"
      [isError]="keysError$ | async"
    >
      <ng-template templateName="listItem" let-item>
        <key-list-item [item]="item"></key-list-item>
      </ng-template>
    </generic-list>
    <xp-modal
      id="key-form-modal"
      [isOpen]="isModalOpen$ | async"
      [closeButtonText]="'ssh_key.generic-object.buttons.close' | translate"
      [saveButtonText]="'ssh_key.generic-object.buttons.save' | translate"
      [titleText]="'ssh_key.generic-object.title' | translate"
      (close)="onModalClose()"
    >
      <ng-template>
        <key-form></key-form>
      </ng-template>
    </xp-modal>
  `,
})
export class KeysListComponent implements AfterViewInit {
  type = GenericListType.keys;
  keysLoading$ = this.store.select(selectKeysLoadingFlag);
  keysError$ = this.store.select(selectKeysErrorFlag);
  isModalOpen$ = this.store.select(selectKeysIsModalOpenFlag);
  keys$ = this.store.select(selectKeys);
  areAllKeysLoaded$ = this.store.select(selectAreAllKeysLoadedFlag);
  queryParams = QueryParamsKeysList;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.store.dispatch(getKeysList({ params: this.queryParams }));
  }

  ngAfterViewInit() {
    if (this.router.url.includes('/keys/new')) {
      this.openCreateModal();
    }
  }

  openCreateModal(): void {
    this.router.navigate(['new'], { relativeTo: this.route });
    this.store.dispatch(openKeysModal());
  }

  onModalClose(): void {
    this.router.navigate(['./'], { relativeTo: this.route });
    this.store.dispatch(closeKeysModal());
  }

  loadMoreKeys(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreKeysList({ params }));
  }
}
