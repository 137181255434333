import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first, skip, tap, map, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GenericListType, LoadMoreQuery } from '../../common/components/lists/generic-list.component';
import { AppState } from '../../store';
import {
  closeConnectionsModal,
  getConnectionItem,
  getConnectionsList,
  loadMoreConnectionsList,
  openConnectionsModal,
} from '../store/connections.actions';
import {
  selectAreAllConnectionsLoadedFlag,
  selectConnectionItem,
  selectConnections,
  selectConnectionsErrorFlag,
  selectConnectionsIsModalOpenFlag,
  selectConnectionsLoadingFlag,
} from '../store/connections.selectors';
import { SearchConfig } from '../../common/services/assisted-search/search-config.model';
import { AnyConnection, ConnectionGroup } from '../connection.models';
import { QueryParamsConnectionsList } from '../../common/helper/query-params-generic-list.helper';
import { ChangeStateService } from '../../common/services/change-state.service';
import { CustomMiddleClickEvent } from '../../common/helper/global-types.helper';
import { Observable, Subscription } from 'rxjs';
import { selectConnectionsGroupItem, selectConnectionsGroupModalOpen } from '../store/connection-groups.selectors';
import {
  clearConnectionGroupItem,
  closeConnectionsGroupModal,
  getConnectionGroupItem,
  openConnectionsGroupModal,
} from '../store/connection-groups.actions';
import { ConnectionGroupsResource } from '../resources/connection-groups.resource';
import { NotifyService } from 'src/app/common/services/notify.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'connections-list',
  template: `
    <generic-list
      [type]="type"
      [items]="connections$ | async"
      [isLoading]="connectionsLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openConnectionCreateModal()"
      [newBtnText]="'connection.generic-object.buttons.new' | translate"
      [emptyTitle]="
        groupId ? 'connection.generic-object.empty.title_group' : ('connection.generic-object.empty.title' | translate)
      "
      [emptyDescription]="'connection.generic-object.empty.description' | translate"
      (loadMore)="loadMoreConnections($event)"
      [limit]="queryParams.limit"
      [allItemsLoaded]="areAllConnectionsLoaded$ | async"
      [searchPlaceholder]="'connection.generic-object.placeholders.search' | translate"
      [searchConfig]="searchConfig"
      [searchQueryFromUrl]="searchQueryFromUrl"
      [hasSearch]="false"
      [isError]="connectionsError$ | async"
      [customListHeader]="true"
      [customCreateButtons]="true"
      [connectionGroupName]="connectionGroup.name"
    >
      <ng-template templateName="listHeader" let-item>
        <div class="generic-list-header-title">
          <a
            class="nav-link"
            [ngClass]="{ active: !groupId }"
            (click)="changeState('connections', $event)"
            (auxclick)="changeState('connections', { isMiddleClick: true })"
          >
            Connections
          </a>
          <a
            class="nav-link"
            (click)="changeState('connections/groups', $event)"
            (auxclick)="changeState('connections/groups', { isMiddleClick: true })"
          >
            Groups
          </a>
          <a *ngIf="item && item.connectionGroupName" class="nav-link" [ngClass]="{ active: !!groupId }">
            {{ item.connectionGroupName }}
          </a>
        </div>
      </ng-template>
      <ng-template templateName="createButtons">
        <div class="generic-list-create-buttons">
          <button
            class="generic-list-new-button btn btn-info btn-lg"
            type="button"
            (click)="createGroup()"
            [ngClass]="{ hidden: !!groupId }"
            *ngxPermissionsOnly="'createConnectionGroup'"
          >
            Create group <span *ngIf="isCreatingGroup">({{ groupItemsCount | async }})</span>
          </button>
          <button
            class="generic-list-new-button btn btn-info btn-lg"
            type="button"
            (click)="openConnectionsGroupCreateModal()"
            [ngClass]="{ hidden: !groupId }"
            *ngxPermissionsOnly="'createConnectionGroup'"
          >
            Edit current group
          </button>
          <button
            class="generic-list-new-button btn btn-primary btn-lg"
            type="button"
            (click)="openConnectionCreateModal()"
            *ngxPermissionsOnly="'createConnection'"
          >
            + {{ 'connection.generic-object.buttons.new' | translate }}
          </button>
        </div>
      </ng-template>
      <ng-template templateName="listItem" let-item>
        <connection-list-item
          [item]="item"
          (edit)="editConnection($event)"
          [isCreatingGroup]="isCreatingGroup"
          [groupId]="groupId"
        ></connection-list-item>
      </ng-template>
      <ng-template templateName="headers">
        <div class="generic-list-headers connections">
          <div class="name">
            <span (click)="changeOrder('name')">
              <span class="sorting">{{ 'generic-list.connections.name' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'name',
                  'fa-chevron-down': queryParams.direction === 'asc' && queryParams.sort === 'name',
                  'fa-chevron-up': queryParams.direction === 'desc' && queryParams.sort === 'name',
                }"
              ></i>
            </span>
          </div>

          <div class="type">
            <span (click)="changeOrder('type')">
              <span class="sorting">{{ 'generic-list.connections.type' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'type',
                  'fa-chevron-down': queryParams.direction === 'asc' && queryParams.sort === 'type',
                  'fa-chevron-up': queryParams.direction === 'desc' && queryParams.sort === 'type',
                }"
              ></i>
            </span>
          </div>

          <div class="owner">{{ 'generic-list.connections.owner' | translate }}</div>
          <div class="date">
            <span (click)="changeOrder('updated')">
              <span class="sorting">{{ 'generic-list.connections.date' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'updated',
                  'fa-chevron-down': queryParams.direction === 'desc' && queryParams.sort === 'updated',
                  'fa-chevron-up': queryParams.direction === 'asc' && queryParams.sort === 'updated',
                }"
              ></i>
            </span>
          </div>
          <div class="dropdown-menu-header"></div>
        </div>
      </ng-template>
    </generic-list>
    <xp-modal
      id="connection-form-modal"
      [isOpen]="isConnectionModalOpen$ | async"
      [closeButtonText]="'connection.generic-object.buttons.close' | translate"
      [saveButtonText]="'connection.generic-object.buttons.save' | translate"
      [titleText]="'connection.generic-object.title' | translate"
      (close)="onConnectionModalClose()"
    >
      <ng-template>
        <connection-form [item]="formItem" [groupId]="groupId"></connection-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      [isOpen]="isConnectionsGroupModalOpen$ | async"
      [closeButtonText]="'connection-group.generic-object.buttons.close' | translate"
      [saveButtonText]="
        formItem.id
          ? 'connection-group.generic-object.buttons.update'
          : ('connection-group.generic-object.buttons.save' | translate)
      "
      [titleText]="
        formItem.id
          ? 'connection-group.generic-object.title_update'
          : ('connection-group.generic-object.title' | translate)
      "
      (close)="onConnectionsGroupModalClose()"
    >
      <ng-template>
        <connections-group-form
          [item]="formGroupItem"
          [groupId]="groupId"
          (close)="onConnectionsGroupModalClose()"
        ></connections-group-form>
      </ng-template>
    </xp-modal>
  `,
})
export class ConnectionsListComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() groupId = '';
  type = GenericListType.connections;
  connectionsLoading$ = this.store.select(selectConnectionsLoadingFlag);
  connectionsError$ = this.store.select(selectConnectionsErrorFlag);
  isConnectionModalOpen$ = this.store.select(selectConnectionsIsModalOpenFlag).pipe(
    skip(1),
    tap((isOpen) => {
      if (!isOpen) {
        if (this.groupId) {
          this.router.navigate(['./', this.groupId], {
            relativeTo: this.route,
          });
        } else {
          this.router.navigate(['./'], { relativeTo: this.route });
        }
      }
    }),
  );
  connections$ = this.store.select(selectConnections);
  areAllConnectionsLoaded$ = this.store.select(selectAreAllConnectionsLoadedFlag);
  isConnectionsGroupModalOpen$ = this.store.select(selectConnectionsGroupModalOpen);
  queryParams = QueryParamsConnectionsList;
  formItem: Partial<AnyConnection> = {};
  searchQueryFromUrl = '';
  searchConfig: SearchConfig = {};
  formGroupItem: Partial<ConnectionGroup> = {};
  isCreatingGroup = false;
  connectionGroup: Partial<ConnectionGroup> = {};
  connectionGroupItemSubscriber: Subscription;
  connectionGroupItem$ = this.store.select(selectConnectionsGroupItem);
  connectionItemSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private changeStateService: ChangeStateService,
  ) {}

  ngOnInit() {
    this.queryParams.connection_group_id = this.groupId;
    this.store.dispatch(getConnectionsList({ params: this.queryParams }));

    const isConnectionsGroupView = !!this.router.url.match(/\/connections\/groups\/\d+/);

    if (isConnectionsGroupView) {
      this.store.dispatch(getConnectionGroupItem({ groupId: Number(this.groupId) }));
    }

    this.connectionGroupItemSubscriber = this.connectionGroupItem$
      .pipe(filter((item) => Boolean(item) && !isEmpty(item)))
      .subscribe((connectionGroupItem: ConnectionGroup) => {
        this.connectionGroup = { ...connectionGroupItem };
        if (isConnectionsGroupView) {
          this.store.dispatch(getConnectionsList({ params: this.queryParams }));
        }
      });
  }

  ngAfterViewInit() {
    const isTypeNewState = !!this.router.url.match(/\/connections\/\w+\/new/);
    if (this.router.url.includes('/connections/new') || this.router.url.match(/\/connections\/\w+\/new/)) {
      this.openConnectionCreateModal(isTypeNewState);
    }

    if (this.router.url.match(/\/connections\/\w+\/\w+\/edit/)) {
      const { type, connection_id } = this.route.firstChild?.firstChild?.snapshot.params;
      this.editConnection({ type, id: connection_id });
    }

    this.connectionItemSubscription = this.store.select(selectConnectionItem).subscribe((item) => {
      this.formItem = { ...item };
    });
  }

  changeOrder(field: string) {
    let { direction } = this.queryParams;
    if (field === this.queryParams.sort) {
      direction = direction === 'asc' ? 'desc' : 'asc';
    }
    this.queryParams = { ...this.queryParams, sort: field, direction };
    this.store.dispatch(getConnectionsList({ params: this.queryParams }));
  }

  editConnection(item: Partial<AnyConnection>) {
    this.store.dispatch(getConnectionItem({ connectionId: item.id, connectionType: item.type }));
    this.formItem = { ...item };
    if (this.groupId) {
      this.router.navigate([`${this.groupId}/${item.type}/${item.id}/edit`], { relativeTo: this.route });
    } else {
      this.router.navigate([`${item.type}/${item.id}/edit`], { relativeTo: this.route });
    }
    this.store.dispatch(openConnectionsModal());
  }

  openConnectionCreateModal(isTypeSelected = false): void {
    this.formItem = {};
    if (isTypeSelected) {
      const { type } = this.route.firstChild?.firstChild?.snapshot.params;
      if (this.groupId) {
        this.router.navigate([`${this.groupId}/${type}/new`], { relativeTo: this.route });
      } else {
        this.router.navigate([`${type}/new`], { relativeTo: this.route });
      }
    } else {
      if (this.groupId) {
        this.router.navigate([`${this.groupId}/new`], { relativeTo: this.route });
      } else {
        this.router.navigate(['new'], { relativeTo: this.route });
      }
    }
    this.store.dispatch(openConnectionsModal());
  }

  onConnectionModalClose(): void {
    this.store.dispatch(closeConnectionsModal());
  }

  loadMoreConnections(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreConnectionsList({ params }));
  }

  changeState(stateName: string, event: MouseEvent | CustomMiddleClickEvent): void {
    this.changeStateService.changeState(stateName, event);
  }

  createGroup(): void {
    this.store.dispatch(clearConnectionGroupItem());
    if (this.isCreatingGroup) {
      this.connections$.pipe(first()).subscribe((connections) => {
        this.formGroupItem = {
          connections: connections.filter((connection) => connection.is_in_group),
        };
        this.store.dispatch(openConnectionsGroupModal());
      });
    } else {
      this.isCreatingGroup = true;
    }
  }

  get groupItemsCount(): Observable<number> {
    return this.connections$.pipe(
      map((connections) => connections.filter((connection) => connection.is_in_group).length),
    );
  }

  onConnectionsGroupModalClose(): void {
    this.isCreatingGroup = false;
    this.store.dispatch(closeConnectionsGroupModal());
  }

  openConnectionsGroupCreateModal(): void {
    this.formGroupItem = {
      ...this.connectionGroup,
    };
    this.store.dispatch(openConnectionsGroupModal());
  }

  ngOnDestroy(): void {
    if (this.connectionGroupItemSubscriber) {
      this.connectionGroupItemSubscriber.unsubscribe();
    }

    if (this.connectionItemSubscription) {
      this.connectionItemSubscription.unsubscribe();
    }
  }
}
