import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../store';
import {
  closeComponentsModal,
  closePackageImportModal,
  closePackageVariablesModal,
  closePackageVersionMessageModal,
} from '../store/package-designer.actions';
import {
  selectComponent,
  selectRawComponent,
  selectIsComponentsModalOpen,
  selectVariables,
  selectIsPackageVariablesModalOpen,
  selectPackage,
  selectIsPackageImportModalOpen,
  selectIsPackageVersionMessageModalOpen,
  selectSecretVariables,
} from '../store/package-designer.selectors';
import { selectConnectionsIsModalOpenFlag } from '../../connections/store/connections.selectors';
import {
  closeConnectionsModal,
  getConnectionItem,
  openConnectionsModal,
} from '../../connections/store/connections.actions';
import { AnyConnection } from '../../connections/connection.models';
import { selectJobsIsModalOpenFlag } from '../../jobs/store/jobs.selectors';
import { selectClustersIsModalOpenFlag } from '../../clusters/store/clusters.selectors';
import { Cluster, ClusterTypes } from '../../clusters/clusters.models';
import { closeJobsModal } from '../../jobs/store/jobs.actions';
import { closeClustersModal } from '../../clusters/store/clusters.actions';
import {
  selectPackagesIsModalOpenFlag,
  selectPackagesIsVersionModalOpenFlag,
} from '../../packages/store/packages.selectors';
import { closePackagesModal, closePackageVersionsModal } from '../../packages/store/packages.actions';
import { Package } from '../../packages/package.models';
import { getDataFromComponent } from '../helpers/components.helpers';
import { DynamicConnectionService } from '../services/dynamic-connection.service';
import { IdleTimeValues } from '../../clusters/services/idle-times.service';

@Component({
  selector: 'designer-modals',
  template: `
    <xp-modal
      [id]="'component-form-modal-' + (component$ | async)?.id"
      [isOpen]="isModalOpen$ | async"
      [closeButtonText]="'package.package-designer.buttons.close' | translate"
      [saveButtonText]="'package.generic-object.buttons.save' | translate"
      [titleText]="'package.generic-object.title' | translate"
      (close)="onModalClose()"
    >
      <ng-template>
        <component-editor
          *ngIf="component$ | async"
          [component]="component$ | async"
          [rawComponent]="rawComponent$ | async"
          (createConnection)="onCreateConnection($event)"
          [packageId]="(package$ | async)?.id"
        ></component-editor>
        <components-list *ngIf="!(component$ | async)"></components-list>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="connection-form-modal"
      [isOpen]="isConnectionModalOpen$ | async"
      [closeButtonText]="'connection.generic-object.buttons.close' | translate"
      [saveButtonText]="'connection.generic-object.buttons.save' | translate"
      [titleText]="'connection.generic-object.title' | translate"
      (close)="onConnectionModalClose()"
    >
      <ng-template>
        <connection-form [item]="formItem" [isInModal]="true"></connection-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="package-variables-form-modal"
      [isOpen]="isPackageVariablesModalOpen$ | async"
      [closeButtonText]="'package.package-designer.buttons.close' | translate"
      [saveButtonText]="'package-variables-editor.buttons.save' | translate"
      [titleText]="'package-variables-editor.title' | translate"
      (close)="onPackageVariablesModalClose()"
    >
      <ng-template>
        <package-variables-editor
          class="package-variables-editor-wrapper modal-variables"
          [package]="package$ | async"
          [variables]="packageVariables$ | async"
          [secretVariables]="secretVariables$ | async"
          [hideRemove]="false"
        ></package-variables-editor>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="cluster-form-modal"
      [isOpen]="isClusterModalOpen$ | async"
      [closeButtonText]="'cluster.generic-object.buttons.close' | translate"
      [saveButtonText]="'cluster.generic-object.buttons.save' | translate"
      [titleText]="'cluster.generic-object.title' | translate"
      (close)="onClusterModalClose()"
    >
      <ng-template>
        <cluster-form
          [item]="clusterFormItem"
          [isInJobsView]="true"
          (clusterCreated)="onClusterCreated($event)"
        ></cluster-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="job-form-modal"
      [isOpen]="isJobModalOpen$ | async"
      [closeButtonText]="'package.package-designer.buttons.close' | translate"
      [saveButtonText]="'job.generic-object.buttons.save' | translate"
      [titleText]="'job.generic-object.title' | translate"
      (close)="onJobModalClose()"
    >
      <ng-template>
        <job-form [package]="package$ | async" [createdCluster]="createdCluster"></job-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="package-form-modal"
      [isOpen]="isPackageModalOpen$ | async"
      [closeButtonText]="'package.package-designer.buttons.close' | translate"
      [saveButtonText]="'package.generic-object.buttons.save' | translate"
      [titleText]="'package.generic-object.title' | translate"
      (close)="onPackageModalClose()"
    >
      <ng-template>
        <package-form [item]="packageFormItem" [isPackageDesigner]="true"></package-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="package-version-form-modal"
      [isOpen]="isVersionsModalOpen$ | async"
      [closeButtonText]="'package.package-designer.buttons.close' | translate"
      [saveButtonText]="'package.generic-object.buttons.save' | translate"
      [titleText]="'package.generic-object.title' | translate"
      (close)="onVersionModalClose()"
    >
      <ng-template>
        <package-version-form [item]="packageFormItem" [isPackageDesigner]="true"></package-version-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="package-import-form-modal"
      [isOpen]="isPackageImportModalOpen$ | async"
      [closeButtonText]="'package.package-designer.buttons.close' | translate"
      [saveButtonText]="'package.controller.modals.import_json.actions.save' | translate"
      [titleText]="'package.controller.modals.import_json.actions.title' | translate"
      (close)="onPackageImportModalClose()"
    >
      <ng-template>
        <package-json-import-form></package-json-import-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="package-version-message-form-modal"
      [isOpen]="isPackageVersionMessageModalOpen$ | async"
      [closeButtonText]="'package.package-designer.buttons.close' | translate"
      [saveButtonText]="'package.controller.modals.major_version.actions.save' | translate"
      [titleText]="'package.controller.modals.major_version.actions.title' | translate"
      (close)="onPackageVersionMessageModalClose()"
    >
      <ng-template>
        <package-version-message-form></package-version-message-form>
      </ng-template>
    </xp-modal>
  `,
})
export class DesignerModalsComponent implements OnInit, OnDestroy {
  isModalOpen$ = this.store.select(selectIsComponentsModalOpen);
  component$ = this.store.select(selectComponent);
  package$ = this.store.select(selectPackage);
  packageVariables$ = this.store.select(selectVariables).pipe(
    withLatestFrom(this.package$),
    map(([variables, packageItem]) => {
      const variablesToEdit = { ...variables };
      const variablesToCache = {};

      (packageItem.components || [])
        .map(getDataFromComponent)
        .map((item) => `${item.name}_connection`)
        .forEach((key) => {
          variablesToCache[key] = variablesToEdit[key];
          delete variablesToEdit[key];
        });

      this.dynamicConnectionService.dynamicConnectionVariables = variablesToCache;

      return variablesToEdit;
    }),
  );
  secretVariables$ = this.store.select(selectSecretVariables);
  rawComponent$ = this.store.select(selectRawComponent);
  isConnectionModalOpen$ = this.store.select(selectConnectionsIsModalOpenFlag);
  isPackageVariablesModalOpen$ = this.store.select(selectIsPackageVariablesModalOpen);
  isJobModalOpen$ = this.store.select(selectJobsIsModalOpenFlag);
  isClusterModalOpen$ = this.store.select(selectClustersIsModalOpenFlag);
  isPackageModalOpen$ = this.store.select(selectPackagesIsModalOpenFlag);
  isVersionsModalOpen$ = this.store.select(selectPackagesIsVersionModalOpenFlag);
  isPackageImportModalOpen$ = this.store.select(selectIsPackageImportModalOpen);
  isPackageVersionMessageModalOpen$ = this.store.select(selectIsPackageVersionMessageModalOpen);
  formItem: Partial<AnyConnection> = {};
  packageFormItem: Partial<Package> = {};
  clusterFormItem: Partial<Cluster> = {
    name: '',
    type: ClusterTypes.sandbox,
    nodes: 1,
    terminate_on_idle: true,
    time_to_idle: IdleTimeValues.ThirtyMinutes,
    reuse_cluster_strategy: 'any',
  };
  createdCluster: Cluster = null;
  packageSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private dynamicConnectionService: DynamicConnectionService,
  ) {}

  ngOnInit() {
    this.packageSubscription = this.package$.subscribe((packageItem) => {
      this.packageFormItem = { ...packageItem };
    });
  }

  onModalClose() {
    this.store.dispatch(closeComponentsModal());
  }

  onCreateConnection(params) {
    if (params.connection?.id) {
      this.store.dispatch(
        getConnectionItem({ connectionId: params.connection.id, connectionType: params.connection.type }),
      );
      this.formItem = { ...params.connection };
    } else {
      this.formItem = { type: params.connection?.type || params.type };
    }
    this.store.dispatch(openConnectionsModal());
  }

  onConnectionModalClose(): void {
    this.store.dispatch(closeConnectionsModal());
  }

  onPackageVariablesModalClose() {
    this.store.dispatch(closePackageVariablesModal());
  }

  onJobModalClose(): void {
    this.store.dispatch(closeJobsModal());
  }

  onPackageModalClose(): void {
    this.store.dispatch(closePackagesModal());
  }

  onVersionModalClose(): void {
    this.store.dispatch(closePackageVersionsModal());
  }

  onPackageImportModalClose(): void {
    this.store.dispatch(closePackageImportModal());
  }

  onPackageVersionMessageModalClose(): void {
    this.store.dispatch(closePackageVersionMessageModal());
  }

  onClusterCreated(cluster: Cluster): void {
    this.createdCluster = cluster;
  }

  onClusterModalClose(): void {
    this.store.dispatch(closeClustersModal());
  }

  ngOnDestroy() {
    if (this.packageSubscription) {
      this.packageSubscription.unsubscribe();
    }
  }
}
