import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GenericListType, LoadMoreQuery } from '../../../common/components/lists/generic-list.component';
import { AppState } from '../../../store';
import {
  closeEditRolesMembersModal,
  closeMembersModal,
  getMembersList,
  loadMoreMembersList,
  openEditRolesMembersModal,
  openMembersModal,
} from '../../store/members/members.actions';
import {
  selectareAllMembersLoaded,
  selectMembers,
  selectMembersIsEditRoleModalOpenFlag,
  selectMembersIsModalOpenFlag,
  selectMembersLoadingFlag,
} from '../../store/members/members.selectors';
import { PermissionsService } from '../../../common/services/permissions.service';
import { QueryParamsMembersList } from '../../../common/helper/query-params-generic-list.helper';
import { Member, STANDARD_ROLES } from '../../members.models';
import { cloneDeep } from 'lodash';
import { map, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'members-list',
  template: `
    <div class="generic-list members-generic-list">
      <div class="generic-list-header members-list-header">
        <div class="generic-list-search members-list-header-description">
          <h2>User Management</h2>
          <p>
            Manage granular access to your account. Check out our
            <a href="https://www.integrate.io/docs/etl/user-management-overview/">documentation</a> for more details.
          </p>
        </div>
        <button
          class="generic-list-new-button btn btn-info btn-lg"
          type="button"
          (click)="openCreateModal()"
          *ngIf="hasPermission('createMember') | async"
        >
          {{ 'member.generic-object.buttons.new' | translate }}
        </button>
      </div>
      <div class="generic-list-header-title">Members</div>
      <div class="generic-list-headers members">
        <div class="avatar"></div>
        <div class="name">
          {{ 'generic-list.members.name' | translate }}
        </div>
        <div class="role">{{ 'generic-list.members.role' | translate }}</div>
        <div class="advanced-role view_role">
          View<i
            class="fa fa-exclamation-circle"
            matTooltip="View workspaces, packages, clusters, jobs, and schedules"
            matTooltipPosition="above"
            matTooltipClass="above"
          ></i>
        </div>
        <div class="advanced-role update_role">
          Update<i
            class="fa fa-exclamation-circle"
            matTooltip="Update packages, run jobs, and provision clusters"
            matTooltipPosition="above"
            matTooltipClass="above"
          ></i>
        </div>
        <div class="advanced-role operate_role">
          Operate<i
            class="fa fa-exclamation-circle"
            matTooltip="Update connections and schedules"
            matTooltipPosition="above"
            matTooltipClass="above"
          ></i>
        </div>
        <div class="advanced-role manage_role">
          Manage<i
            class="fa fa-exclamation-circle"
            matTooltip="Manage account settings"
            matTooltipPosition="above"
            matTooltipClass="above"
          ></i>
        </div>
        <div class="remove"></div>
      </div>
      <member-list-item
        *ngFor="let item of members$ | async; trackBy: identify"
        [item]="item"
        (editRolesClick)="openEditRolesModal($event)"
      ></member-list-item>

      <div class="generic-list-loader" *ngIf="membersLoading$ | async">
        <xp-loader></xp-loader>
      </div>

      <div class="generic-list-header-title second-title">Admins</div>
      <div class="generic-list-headers members">
        <div class="avatar"></div>
        <div class="name">
          {{ 'generic-list.members.name' | translate }}
        </div>
        <div class="role">{{ 'generic-list.members.role' | translate }}</div>
        <div class="advanced-role view_role">
          View<i
            class="fa fa-exclamation-circle"
            matTooltip="View connections, workspaces, packages, clusters, jobs, and schedules"
            matTooltipPosition="above"
            matTooltipClass="above"
          ></i>
        </div>
        <div class="advanced-role update_role">
          Update<i
            class="fa fa-exclamation-circle"
            matTooltip="Update packages, run jobs, and provision clusters"
            matTooltipPosition="above"
            matTooltipClass="above"
          ></i>
        </div>
        <div class="advanced-role operate_role">
          Operate<i
            class="fa fa-exclamation-circle"
            matTooltip="Update connections and schedules"
            matTooltipPosition="above"
            matTooltipClass="above"
          ></i>
        </div>
        <div class="advanced-role manage_role">
          Manage<i
            class="fa fa-exclamation-circle"
            matTooltip="Manage account settings"
            matTooltipPosition="above"
            matTooltipClass="above"
          ></i>
        </div>
        <div class="remove"></div>
      </div>
      <member-list-item
        *ngFor="let item of admins$ | async; trackBy: identify"
        [item]="item"
        (editRolesClick)="openEditRolesModal($event)"
      ></member-list-item>

      <div class="generic-list-loader" *ngIf="membersLoading$ | async">
        <xp-loader></xp-loader>
      </div>
    </div>
    <xp-modal
      id="member-form-modal"
      [isOpen]="isModalOpen$ | async"
      [closeButtonText]="'member.generic-object.buttons.close' | translate"
      [saveButtonText]="'member.generic-object.buttons.save' | translate"
      [titleText]="'member.generic-object.title' | translate"
      (close)="onModalClose()"
      [isVerySmall]="true"
    >
      <ng-template>
        <member-form></member-form>
      </ng-template>
    </xp-modal>
    <xp-modal
      id="member-edit-form-modal"
      [isOpen]="isEditRolesModalOpen$ | async"
      [closeButtonText]="'member.generic-object.buttons.close' | translate"
      [saveButtonText]="'member.generic-object.buttons.update' | translate"
      [titleText]="'member.generic-object.edit' | translate"
      [isVerySmall]="true"
      (close)="onEditRolesModalClose()"
    >
      <ng-template>
        <member-edit-form [item]="member"></member-edit-form>
      </ng-template>
    </xp-modal>
  `,
})
export class MembersListComponent implements AfterViewInit {
  type = GenericListType.members;
  member: Partial<Member> = {
    role: STANDARD_ROLES.READER,
  };
  membersLoading$ = this.store.select(selectMembersLoadingFlag);
  isModalOpen$ = this.store.select(selectMembersIsModalOpenFlag);
  isEditRolesModalOpen$ = this.store.select(selectMembersIsEditRoleModalOpenFlag);
  allMembers$ = this.store.select(selectMembers);
  areAllMembersLoaded$ = this.store.select(selectareAllMembersLoaded);
  queryParams = QueryParamsMembersList;
  members$ = this.allMembers$.pipe(map((members) => members.filter((member) => member.role !== STANDARD_ROLES.ADMIN)));
  admins$ = this.allMembers$.pipe(map((members) => members.filter((member) => member.role === STANDARD_ROLES.ADMIN)));
  limit = QueryParamsMembersList.limit;
  offset = QueryParamsMembersList.offset;

  constructor(
    private store: Store<AppState>,
    private permissionsService: PermissionsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.store.dispatch(getMembersList({ params: this.queryParams }));

    this.members$.pipe(withLatestFrom(this.areAllMembersLoaded$)).subscribe(([members, areAllMembersLoaded]) => {
      if (!areAllMembersLoaded && members.length > 0) {
        this.offset = this.offset + this.limit;
        this.loadMoreMembers({ limit: this.limit, offset: this.offset });
      }
    });
  }

  ngAfterViewInit() {
    if (this.router.url.includes('/members/new')) {
      this.openCreateModal();
    }

    if (this.router.url.includes('/invitation')) {
      this.router.navigate(['/invitation'], { queryParams: { token: this.route.snapshot.params.token } });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  identify(index: number, item: any) {
    return item.id;
  }

  openCreateModal(): void {
    this.router.navigate(['new'], { relativeTo: this.route });
    this.store.dispatch(openMembersModal());
  }

  onModalClose(): void {
    this.router.navigate(['./'], { relativeTo: this.route });
    this.store.dispatch(closeMembersModal());
  }

  openEditRolesModal(item: Member): void {
    // this.router.navigate(['edit'], { relativeTo: this.route });
    this.member = cloneDeep(item);
    this.store.dispatch(openEditRolesMembersModal());
  }

  onEditRolesModalClose(): void {
    this.router.navigate(['./'], { relativeTo: this.route });
    this.store.dispatch(closeEditRolesMembersModal());
  }

  loadMoreMembers(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreMembersList({ params }));
  }

  hasPermission(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }
}
