import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { AppState } from './store';
import { closeShortcutsModal, openShortcutsModal } from './common/store/app-common.actions';
import { selectIsShortcutsModalOpenFlag } from './common/store/app-common.selectors';
import { ShortcutsService } from './package-designer/services/shortcuts.service';
import { AuthService } from './common/services/auth.service';
import { selectIsLogged, selectIsLoggedOut } from './account/store/account.selectors';
import { clearAccountsList, updateUserInfo } from './account/store/account.actions';
import { UsersResource } from './account/resources/users.resource';
import { PusherService } from './common/services/pusher.service';
import { SegmentService } from './common/services/segment.service';
import { AccountsService } from './account/services/accounts.service';
import { PermissionsService } from './common/services/permissions.service';
import { ValidationExportsService } from './common/validators/validationExports.service';
import en from '../locales/en';
import { VisibilityService } from './common/services/visibility.service';
import { User } from './account/user.models';
import { AuthorizationGuard } from './common/services/authorization.guard';
import { BoomerangService } from './account/services/boomerang.service';
import { NotifyService } from './common/services/notify.service';
import { closeAllModals } from './account/store/account.actions';
import { environment } from '../environments/environment';
import { setIntegrateIOFlag, setSSOFlag } from './account/helpers/account.helpers';
import { MatDialog } from '@angular/material/dialog';
import { CommandBarService } from './common/services/command-bar.service';

@Component({
  selector: 'app-root',
  template: `
    <icons-sprite class="hidden"></icons-sprite>
    <ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>
    <xp-modal
      id="shortcuts-modal"
      [isOpen]="isShortcutsModalOpen$ | async"
      [closeButtonText]="'app.controller.modals.shortcuts.actions.close' | translate"
      [saveButtonText]="'package.generic-object.buttons.save' | translate"
      [titleText]="'app.controller.modals.shortcuts.actions.title' | translate"
      (close)="onShortcutsModalClose()"
      [isSmall]="true"
    >
      <ng-template>
        <xp-shortcuts></xp-shortcuts>
      </ng-template>
    </xp-modal>
    <router-outlet></router-outlet>
    <xp-expression-editor></xp-expression-editor>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  isShortcutsModalOpen$ = this.store.select(selectIsShortcutsModalOpenFlag);
  user: User;
  shortcutsSubscriptions: Subscription[] = [];
  pressedKeys: any = {};
  isPasswordResetURL = false;

  constructor(
    private store: Store<AppState>,
    private shortcutsService: ShortcutsService,
    private authService: AuthService,
    private userResource: UsersResource,
    private pusherService: PusherService,
    private segmentService: SegmentService,
    private accountService: AccountsService,
    private permissionService: PermissionsService,
    private validationExportsService: ValidationExportsService,
    private readonly translate: TranslateService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private visibilityService: VisibilityService,
    private authGuard: AuthorizationGuard,
    private boomerangService: BoomerangService,
    private notify: NotifyService,
    private commandBar: CommandBarService,
  ) {}

  ngOnInit() {
    // this.commandBar.init();
    this.translate.setTranslation('en', en);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.boomerangService.init();

    const perm = [];
    this.permissionsService.loadPermissions(perm);

    if (!window.location.pathname.includes('ping')) {
      this.validationExportsService.loadValidations();
    }

    this.isPasswordResetURL = window.location.href.includes('password_reset');

    if (!this.isPasswordResetURL) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.segmentService.init(environment.SEGMENT_PUBLIC_KEY);
    }
    this.shortcutsSubscriptions = [
      this.shortcutsService.addShortcut({ shouldPreventInInput: true, keys: 'shift.?' }).subscribe(() => {
        this.store.dispatch(openShortcutsModal());
      }),
      this.shortcutsService.addShortcut({ shouldPreventInInput: true, keys: 'meta./' }).subscribe(() => {
        this.store.dispatch(openShortcutsModal());
      }),
    ];

    this.document.addEventListener('keydown', this.keyDownListener.bind(this));
    this.document.addEventListener('keyup', this.keyUpListener.bind(this));

    if (this.authService.isAuthenticated()) {
      setTimeout(() => {
        this.getUserAndAccountsData();
      });
    } else if (
      !window.location.pathname.includes('auth') &&
      !window.location.pathname.includes('ping') &&
      !window.location.pathname.includes('signup') &&
      !window.location.pathname.includes('partners')
    ) {
      this.router.navigate(['/auth/login']);
    }

    this.store.pipe(selectIsLogged).subscribe(() => {
      this.getUserAndAccountsData();
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          // eslint-disable-next-line no-param-reassign
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event: any) => {
        let title = 'ETL & Reverse ETL';
        if (event.title) {
          title = `${event.title} · ETL & Reverse ETL`;
        }
        document.title = title;

        if (!this.isPasswordResetURL) {
          this.segmentService.analytics.page(document.title, {
            path: window.location.pathname,
            search: window.location.search,
            referrer: '',
            title,
            url: window.location.href,
          });

          if (this.user) {
            this.segmentService.analytics.identify(this.user.email, { ...this.user, product_name: 'ETL' });

            window['intercomSettings'] = {
              api_base: 'https://api-iam.intercom.io',
              app_id: 'ln2h9and',
              email: this.user.email,
              user_hash: this.user.intercom_identity_verification_hash,
              hide_default_launcher: true,
              alignment: 'left',
              ...this.user,
              product_name: 'ETL',
              user_id: this.user.email,
            };

            setTimeout(() => {
              window['Intercom']('boot');
            }, 0);
          }
        }
      });

    this.visibilityService.init();

    this.store.pipe(selectIsLoggedOut).subscribe(() => {
      this.pusherService.unsubscribeUser();
      this.pusherService.unsubscribeAccount();
      this.permissionsService.flushPermissions();
    });
  }

  ngAfterViewInit() {
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          ((e as any).type = 'text/javascript'),
            ((e as any).async = !0),
            ((e as any).src = 'https://canny.io/sdk.js'),
            f.parentNode.insertBefore(e, f);
        }
      }
      if ('function' != typeof (w as any).Canny) {
        var c = function () {
          (c as any).q.push(arguments);
        };
        ((c as any).q = []),
          ((w as any).Canny = c),
          'complete' === d.readyState
            ? l()
            : (w as any).attachEvent
              ? (w as any).attachEvent('onload', l)
              : w.addEventListener('load', l, !1);
      }
    })(window, document, 'canny-jssdk', 'script');
  }

  getUserAndAccountsData() {
    this.store.dispatch(clearAccountsList());

    this.userResource
      .get({
        include: 'event_traits',
      } as any)
      .subscribe({
        next: (user) => {
          this.user = user;
          setIntegrateIOFlag(user);
          setSSOFlag(user);
          this.store.dispatch(updateUserInfo({ user }));
          this.pusherService.initUser(user.id);

          if (!this.isPasswordResetURL) {
            this.segmentService.analytics.identify(user.email, { ...user.event_traits, product_name: 'ETL' });

            window['intercomSettings'] = {
              api_base: 'https://api-iam.intercom.io',
              app_id: 'ln2h9and',
              email: this.user.email,
              user_hash: this.user.intercom_identity_verification_hash,
              hide_default_launcher: true,
              alignment: 'left',
              ...user.event_traits,
              product_name: 'ETL',
              user_id: this.user.email,
            };
            setTimeout(() => {
              window['Intercom']('boot');
            }, 0);
          }
          // eslint-disable-next-line @typescript-eslint/dot-notation
          window['DEFAULT_TIMEZONE'] = user.time_zone_info.offset;
          window['DEFAULT_TIMEZONE_NAME'] = user.time_zone_info.name;

          this.authGuard.getAccount$().subscribe((account) => {
            this.commandBar.initUser(user, account);
            this.accountService.currentAccount = account;
            // this.permissionService.init([...accountService.accounts, account]);

            if (environment.SENTRY_ENABLED) {
              Sentry.setUser({
                id: user.id,
                email: user.email,
                username: account.account_id,
              });
            }
          });
        },
        error: () => {
          this.notify.error('An error occurred while loading account data.');
        },
      });

    this.accountService.loadAccounts().subscribe();
  }

  onShortcutsModalClose() {
    this.store.dispatch(closeShortcutsModal());
  }

  keyDownListener(e: KeyboardEvent) {
    this.pressedKeys[e.key] = true;

    const accountId = this.authGuard.account?.account_id;

    if (
      !accountId ||
      (e.target as HTMLElement).tagName === 'INPUT' ||
      (e.target as HTMLElement).tagName === 'TEXTAREA'
    ) {
      return;
    }

    if (this.pressedKeys.g && e.key === 'i') {
      this.router.navigate([`/${accountId}/connections`]);
      this.store.dispatch(closeAllModals());
    }

    if (this.pressedKeys.g && e.key === 'p') {
      this.router.navigate([`/${accountId}/packages`]);
      this.store.dispatch(closeAllModals());
    }

    if (this.pressedKeys.g && e.key === 'j') {
      this.router.navigate([`/${accountId}/jobs`]);
      this.store.dispatch(closeAllModals());
    }

    if (this.pressedKeys.g && e.key === 'c') {
      this.router.navigate([`/${accountId}/clusters`]);
      this.store.dispatch(closeAllModals());
    }

    if (this.pressedKeys.g && e.key === 's') {
      this.router.navigate([`/${accountId}/schedules`]);
      this.store.dispatch(closeAllModals());
    }

    if (e.key === 'Escape') {
      this.store.dispatch(closeAllModals());
    }
  }

  keyUpListener(e: KeyboardEvent) {
    this.pressedKeys = {};
  }

  ngOnDestroy() {
    this.shortcutsSubscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.document.removeEventListener('keydown', this.keyDownListener.bind(this));
    this.document.removeEventListener('keyup', this.keyUpListener.bind(this));
    (window as any).CommandBar.shutdown();
  }
}
