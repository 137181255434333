import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GenericListType, LoadMoreQuery } from '../../../common/components/lists/generic-list.component';
import { AppState } from '../../../store';
import {
  closeAccountsModal,
  getAccountsList,
  loadMoreAccountsList,
  openAccountsModal,
} from '../../store/accounts/accounts.actions';
import {
  selectAccounts,
  selectAccountsErrorFlag,
  selectAccountsIsModalOpenFlag,
  selectAccountsLoadingFlag,
  selectAreAllAccountsLoadedFlag,
} from '../../store/accounts/accounts.selectors';
import { QueryParamsAccountsList } from '../../../common/helper/query-params-generic-list.helper';

@Component({
  selector: 'accounts-list',
  template: `
    <generic-list
      [type]="type"
      [items]="accounts$ | async"
      [isLoading]="accountsLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openCreateModal()"
      [newBtnText]="'account.generic-object.buttons.new' | translate"
      [emptyTitle]="'account.generic-object.empty.title' | translate"
      [emptyDescription]="'account.generic-object.empty.description' | translate"
      (loadMore)="loadMoreAccounts($event)"
      [limit]="queryParams.limit"
      [allItemsLoaded]="areAllAccountsLoaded$ | async"
      [isError]="accountsError$ | async"
    >
      <ng-template templateName="listItem" let-item>
        <account-list-item [item]="item"></account-list-item>
      </ng-template>
    </generic-list>
    <xp-modal
      id="account-form-modal"
      [isOpen]="isModalOpen$ | async"
      [closeButtonText]="'account.generic-object.buttons.close' | translate"
      [saveButtonText]="'account.generic-object.buttons.save' | translate"
      [titleText]="'account.generic-object.title' | translate"
      (close)="onModalClose()"
      [isSmall]="true"
    >
      <ng-template>
        <account-form></account-form>
      </ng-template>
    </xp-modal>
  `,
})
export class AccountsListComponent implements AfterViewInit {
  type = GenericListType.accounts;
  accountsLoading$ = this.store.select(selectAccountsLoadingFlag);
  accountsError$ = this.store.select(selectAccountsErrorFlag);
  isModalOpen$ = this.store.select(selectAccountsIsModalOpenFlag);
  accounts$ = this.store.select(selectAccounts);
  areAllAccountsLoaded$ = this.store.select(selectAreAllAccountsLoadedFlag);
  queryParams = QueryParamsAccountsList;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.store.dispatch(getAccountsList({ params: this.queryParams }));
  }

  ngAfterViewInit() {
    if (this.router.url.includes('/accounts/new')) {
      this.openCreateModal();
    }
  }

  openCreateModal(): void {
    this.router.navigate(['new'], { relativeTo: this.route });
    this.store.dispatch(openAccountsModal());
  }

  onModalClose(): void {
    this.router.navigate(['./'], { relativeTo: this.route });
    this.store.dispatch(closeAccountsModal());
  }

  loadMoreAccounts(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreAccountsList({ params }));
  }
}
