import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, first, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AppState } from '../../../store';
import {
  fixComponentData,
  getComponentInputs,
  getSourceComponentsFromPartialGrap,
} from '../../helpers/components.helpers';
import {
  selectComponentFormValidFlag,
  selectPackage,
  selectPackageAndComponent,
  selectVariables,
} from '../../store/package-designer.selectors';
import { selectAccountId, selectComponentPreviewerFlag } from '../../../account/store/account.selectors';
import { getDataForSchemaImporterRawComponent } from '../../helpers/schema.helpers';
import { getPartialGraphForComponentPreviewer } from '../../helpers/component-graph.helpers';
import { postComponentPreviewerData } from '../../store/component-previewer.actions';
import {
  selectPackagePreviewIsErrorFlag,
  selectPackagePreviewIsInfoFlag,
  selectPackagePreviewIsValidFlag,
  selectPackagePreviewLoadingFlag,
  selectPackagePreviewLogs,
  selectPackagePreviewProgress,
  selectPackagePreviewResult,
} from '../../store/component-previewer.selectors';
import { getSettingsForComponent, SchemaImporterSettings } from '../../helpers/schema-importer-settings.helper';
import { resetLogMessagesCache } from '../../helpers/component-previewer.helpers';
import { inputComponentsLength } from '../../helpers/component-schema.helpers';

@Component({
  selector: 'component-previewer',
  // eslint-disable-next-line global-require
  template: `
    <div class="component-previewer" *ngIf="(isComponentPreviewerEnabled$ | async) && (sourceComponent$ | async)">
      <button type="button" class="btn btn-md btn-info" (click)="getPreview()" [disabled]="isButtonDisabled$ | async">
        {{ 'component-previewer.actions.preview_button' | translate }}
      </button>
      <div class="progress-bar-container" *ngIf="(isLoading$ | async) || (progress$ | async)">
        <ngb-progressbar
          type="success"
          textType="white"
          [animated]="true"
          [value]="(progress$ | async) * 100"
          [showValue]="(progress$ | async) !== 1"
          [striped]="true"
          >{{ (progress$ | async) === 1 ? 'Completed!' : '' }}</ngb-progressbar
        >
      </div>
      <span *ngIf="error$ | async">{{ error$ | async }}</span>
      <pre class="payload-container" *ngIf="previewLogs$ | async">
          <span [className]="log.status" *ngFor="let log of (previewLogs$ | async)">{{ log.message }} </span>
      </pre>
      <div class="component-previewer-data">
        <schema-importer-data-preview
          [data]="previewResult$ | async"
          [component]="sourceComponent$ | async"
          [rawComponent]="sourceComponent$ | async"
          [options]="settings"
          [isComponentPreviewer]="true"
          [isPreviewLoading]="isLoading$ | async"
        ></schema-importer-data-preview>
      </div>
    </div>
  `,
  providers: [],
})
export class ComponentPreviewerComponent {
  @Input() componentId: string;

  isComponentPreviewerEnabled$ = this.store.select(selectComponentPreviewerFlag);
  componentAndPackageData$ = this.store.select(selectPackageAndComponent);
  componentsData$ = this.componentAndPackageData$.pipe(
    map(({ component, packageItem }) => getPartialGraphForComponentPreviewer(this.componentId)(packageItem, component)),
  );
  accountId$ = this.store.pipe(selectAccountId);
  variables$ = this.store.select(selectVariables);
  package$ = this.store.select(selectPackage);
  isComponentFormValid$ = this.store.select(selectComponentFormValidFlag);
  isLoading$ = this.store.select(selectPackagePreviewLoadingFlag);
  progress$ = this.store.select(selectPackagePreviewProgress);
  isError$ = this.store.select(selectPackagePreviewIsErrorFlag);
  isInfo$ = this.store.select(selectPackagePreviewIsInfoFlag);
  areCurrentFieldsValid$ = this.store.select(selectPackagePreviewIsValidFlag);
  previewResult$ = this.store.select(selectPackagePreviewResult).pipe(map((data) => JSON.stringify(data, null, 2)));
  previewLogs$ = this.store.select(selectPackagePreviewLogs).pipe(
    filter((logs) => !!logs.length),
    map((logs) => logs.filter(Boolean)),
  );
  sourceComponents$ = this.componentsData$.pipe(map(getSourceComponentsFromPartialGrap));
  componentInputs$ = this.componentAndPackageData$.pipe(
    map(({ packageItem }) => getComponentInputs(packageItem, this.componentId)),
  );
  sourceComponent$ = this.sourceComponents$.pipe(map((component) => fixComponentData(component[0])));
  settings: Partial<SchemaImporterSettings>;
  isButtonDisabled$ = combineLatest([
    this.isLoading$,
    this.isComponentFormValid$,
    this.previewResult$,
    this.isError$,
    this.isInfo$,
    this.areCurrentFieldsValid$,
    this.componentInputs$,
    this.componentAndPackageData$,
  ]).pipe(
    map(
      ([
        isLoading,
        isComponentFormValid,
        previewResult,
        isError,
        isInfo,
        areCurrentFieldsValid,
        componentInputs,
        { component },
      ]) => {
        if (
          !isComponentFormValid ||
          isLoading ||
          !areCurrentFieldsValid ||
          (component && componentInputs.length < inputComponentsLength(component.componentType))
        ) {
          return true;
        }

        if (this.isButtonClicked && !isError) {
          return isInfo ? false : !previewResult;
        }

        return false;
      },
    ),
  );
  isButtonClicked = false;

  constructor(private store: Store<AppState>) {}

  public getPreview() {
    this.isButtonClicked = true;
    combineLatest([this.componentsData$, this.accountId$, this.variables$, this.package$, this.sourceComponent$])
      .pipe(
        map(([componentsData, accountId, variables, packageItem, sourceComponent]) => {
          const sourceComponents = getSourceComponentsFromPartialGrap(componentsData);
          const source_schema_importer_parameters = sourceComponents.map((sourceComponentItem) =>
            getDataForSchemaImporterRawComponent(sourceComponentItem, accountId as string, true),
          );

          const { components, edges, destination_schema } = componentsData;

          const settings = getSettingsForComponent(sourceComponent, sourceComponent as any);

          return {
            data: {
              data_flow_json: {
                components,
                edges,
              },
              destination_schema,
              variables,
              source_schema_importer_parameters,
              package_id: packageItem.id,
            },
            settings,
            package_id: packageItem.id,
          };
        }),
        first(),
      )
      .subscribe(({ data, settings, package_id }) => {
        resetLogMessagesCache();
        this.settings = settings;
        this.store.dispatch(postComponentPreviewerData({ data, package_id: data.package_id }));
      });
  }
}
