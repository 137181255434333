import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { GenericListType, LoadMoreQuery } from '../../common/components/lists/generic-list.component';
import { AppState } from '../../store';
import {
  closeSchedulesModal,
  getSchedulesList,
  loadMoreSchedulesList,
  openSchedulesModal,
  searchSchedulesList,
} from '../store/schedules.actions';
import {
  selectAreAllSchedulesLoadedFlag,
  selectSchedules,
  selectSchedulesErrorFlag,
  selectSchedulesIsModalOpenFlag,
  selectSchedulesLoadingFlag,
} from '../store/schedules.selectors';
import { Schedule } from '../schedule.models';
import { AssistedSearchSchedulesConfiguratorService } from '../../common/services/assisted-search/assisted-search-schedules-configurator.service';
import { SearchConfig } from '../../common/services/assisted-search/search-config.model';
import { areVariablesOverridden } from './package-picker-form.component';
import { QueryParamsSchedulesList } from '../../common/helper/query-params-generic-list.helper';
import { IdleTimeValues } from '../../clusters/services/idle-times.service';
import { ClusterTypes } from '../../clusters/clusters.models';
import { SchedulesResource } from '../resources/schedules.resource';
import { NotifyService } from '../../common/services/notify.service';

@Component({
  selector: 'schedules-list',
  template: `
    <generic-list
      [type]="type"
      [items]="schedules$ | async"
      [isLoading]="schedulesLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openCreateModal()"
      [newBtnText]="'schedule.generic-object.buttons.new' | translate"
      [emptyTitle]="'schedule.generic-object.empty.title' | translate"
      [emptyDescription]="'schedule.generic-object.empty.description' | translate"
      (loadMore)="loadMoreSchedules($event)"
      [allItemsLoaded]="areAllSchedulesLoaded$ | async"
      [limit]="queryParams.limit"
      [searchPlaceholder]="'schedule.generic-object.placeholders.search' | translate"
      [searchConfig]="searchConfig"
      [searchQueryFromUrl]="searchQueryFromUrl"
      [hasSearch]="true"
      [isError]="schedulesError$ | async"
      (searchSubmit)="onSearchSubmit($event)"
    >
      <ng-template templateName="listItem" let-item>
        <schedule-list-item
          [item]="item"
          [searchValue]="searchValue"
          (edit)="editSchedule($event)"
        ></schedule-list-item>
      </ng-template>

      <ng-template templateName="headers">
        <div class="generic-list-headers schedules">
          <div class="name">
            <span (click)="changeOrder('name')">
              <span class="sorting">{{ 'generic-list.schedules.name' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'name',
                  'fa-chevron-down': queryParams.direction === 'desc' && queryParams.sort === 'name',
                  'fa-chevron-up': queryParams.direction === 'asc' && queryParams.sort === 'name',
                }"
              ></i>
            </span>
          </div>
          <div class="owner">{{ 'generic-list.schedules.owner' | translate }}</div>
          <div class="repeat">{{ 'generic-list.schedules.repeat' | translate }}</div>
          <div class="last-run">{{ 'generic-list.schedules.last_run' | translate }}</div>
          <div class="next-run">{{ 'generic-list.schedules.next_run' | translate }}</div>
        </div>
      </ng-template>
    </generic-list>
    <xp-modal
      id="schedule-form-modal"
      [isOpen]="isModalOpen$ | async"
      [closeButtonText]="'schedule.generic-object.buttons.close' | translate"
      [saveButtonText]="'schedule.generic-object.buttons.save' | translate"
      [titleText]="'schedule.generic-object.title' | translate"
      (close)="onModalClose()"
    >
      <ng-template>
        <schedule-form [item]="formItem" [isLoadingItem]="isLoadingItem"></schedule-form>
      </ng-template>
    </xp-modal>
  `,
})
export class SchedulesListComponent implements AfterViewInit, OnInit, OnDestroy {
  type = GenericListType.schedules;
  schedulesLoading$ = this.store.select(selectSchedulesLoadingFlag);
  schedulesError$ = this.store.select(selectSchedulesErrorFlag);
  isModalOpen$ = this.store.select(selectSchedulesIsModalOpenFlag);
  schedules$ = this.store.select(selectSchedules);
  areAllSchedulesLoaded$ = this.store.select(selectAreAllSchedulesLoadedFlag);
  queryParams = QueryParamsSchedulesList;
  formItem: Partial<Schedule> = {};
  searchQueryFromUrl = '';
  searchConfig: SearchConfig = {};
  searchSubscription: Subscription;
  isLoadingItem = false;
  searchQuery = '';
  searchValue = '';

  constructor(
    private store: Store<AppState>,
    private assistedSearchSchedulesConfigurator: AssistedSearchSchedulesConfiguratorService,
    private router: Router,
    private route: ActivatedRoute,
    private schedulesResource: SchedulesResource,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    const query = this.route.snapshot.queryParams.query;

    this.searchQueryFromUrl = query || '';

    this.searchSubscription = this.assistedSearchSchedulesConfigurator.getConfig$().subscribe((config) => {
      this.searchConfig = config;
    });

    if (!query) {
      this.store.dispatch(getSchedulesList({ params: this.queryParams }));
    }
  }

  ngAfterViewInit() {
    if (this.router.url.includes('/schedules/new')) {
      this.openCreateModal();
    }

    if (this.router.url.match(/\/schedules\/\w+\/edit/)) {
      this.schedules$
        .pipe(
          filter((schedules) => schedules.length > 0),
          first(),
        )
        .subscribe((schedules) => {
          const { schedule_id } = this.route.firstChild?.snapshot.params;
          const foundSchedule = schedules.find((item) => item.id === Number(schedule_id));
          if (!foundSchedule) {
            this.editSchedule({ id: schedule_id });
          } else {
            this.editSchedule(foundSchedule);
          }
        });
    }
  }

  changeOrder(field: string) {
    let { direction } = this.queryParams;
    if (field === this.queryParams.sort) {
      direction = direction === 'asc' ? 'desc' : 'asc';
    }
    this.queryParams = { ...this.queryParams, sort: field, direction };
    this.store.dispatch(getSchedulesList({ params: this.queryParams }));
  }

  editSchedule(item: Partial<Schedule>) {
    this.schedulesResource.get(item.id, { include: 'package' }).subscribe({
      next: (schedule) => {
        this.formItem = cloneDeep(schedule);
        if (!this.formItem.task) {
          this.formItem.task = {
            name: '',
            type: ClusterTypes.production,
            nodes: 1,
            terminate_on_idle: true,
            time_to_idle: IdleTimeValues.TenMinutes,
            reuse_cluster_strategy: 'any',
            packages: [],
          };
        }
        this.formItem.task.packages = this.formItem.task.packages.map((packageItem) => {
          const newPackage = {
            ...packageItem.package,
            variables: packageItem.variables || packageItem.package.variables,
            defaultVariables: packageItem.package.variables,
            paper_trail_package_version: packageItem.paper_trail_package_version,
            package_version: packageItem.package_version,
          };
          newPackage.overrideVariables = areVariablesOverridden(newPackage);
          return newPackage;
        });
        this.isLoadingItem = false;
      },
      error: () => {
        this.notify.error('An error occurred while loading schedule item.');
        this.isLoadingItem = false;
      },
    });
    this.router.navigate([`${item.id}/edit`], { relativeTo: this.route });
    this.store.dispatch(openSchedulesModal());
    this.isLoadingItem = true;
  }

  openCreateModal(): void {
    this.formItem = {
      interval_amount: 7,
      interval_unit: 'days',
      overlap: false,
      reuse_cluster_strategy: 'any',
      status: 'enabled',
      task: {
        name: '',
        type: ClusterTypes.production,
        nodes: 1,
        terminate_on_idle: true,
        time_to_idle: IdleTimeValues.TenMinutes,
        reuse_cluster_strategy: 'any',
        packages: [],
      },
    };
    this.router.navigate(['new'], { relativeTo: this.route });
    this.store.dispatch(openSchedulesModal());
  }

  onModalClose(): void {
    const queryParams = this.searchQuery ? { query: this.searchQuery } : {};
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    this.store.dispatch(closeSchedulesModal());
  }

  loadMoreSchedules(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreSchedulesList({ params }));
  }

  onSearchSubmit({ query, text }) {
    const params = { ...this.queryParams, q: query };
    this.queryParams = params;
    this.searchQuery = query;

    if (!query) {
      this.store.dispatch(getSchedulesList({ params: this.queryParams }));
    } else {
      this.store.dispatch(searchSchedulesList({ params }));
    }

    this.schedulesLoading$
      .pipe(
        filter((isLoading) => !isLoading),
        first(),
      )
      .subscribe(() => {
        this.searchValue = text || '';
      });
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
}
