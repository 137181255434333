import { COMPONENT_TYPE } from './component_type';
import {
  AggregatedField,
  AllComponentData,
  Analytics4SourceComponentData,
  AnyComponentData,
  ColumnMap,
  Connection,
  CSVDestinationType,
  ExpressionField,
  Field,
  GroupedField,
  RECORD_DELIMITER,
  RECORD_TYPE,
  Relation,
  RestApiDestinationComponentData,
  WindowedField,
} from '../package-designer/package.models';
import { ConnectionTypeName } from '../connections/connection.models';
import { Package } from '../packages/package.models';
import { SALESFORCE_API_VERSION } from './salesforce_api_versions';

const PYTHON_EXAMPLE_CODE = `
import json

def transform(event, context):
    """
    Transforms input records by converting all string values to uppercase.
    IMPORTANT: The output schema must match the input schema exactly.
    Any structural changes to the data will cause errors in the pipeline.
    
    Args:
        event (list): Array of records to process. Each record is a dictionary.
        context: Lambda context object (not used in this example)
    
    Returns:
        list: Transformed records with the same schema as input
    
    Raises:
        Exception: If any error occurs during processing
    """
    try:
        # Process array input - event is always an array of records
        data = event
        
        # Initialize results array to store transformed records
        results = []
        
        # Process each record while maintaining the original schema
        for record in data:
            # Create a new dictionary for the transformed record
            transformed_record = {}
            
            # Process each field while preserving field names and data types
            for key, value in record.items():
                # Only transform string values, leave other types unchanged
                if isinstance(value, str):
                    transformed_record[key] = value.upper()
                else:
                    transformed_record[key] = value
                    
            # Add transformed record to results, maintaining array structure
            results.append(transformed_record)
            
        # Return results with same schema as input
        return results
        
    except Exception as e:
        # Propagate error to Lambda runtime
        raise e

# Example input:
# [
#     {"id": 1, "name": "Belgian Waffles", "price": 5.95},
#     {"id": 2, "name": "Pancakes", "price": 4.95}
# ]
#
# Example output:
# [
#     {"id": 1, "name": "BELGIAN WAFFLES", "price": 5.95},
#     {"id": 2, "name": "PANCAKES", "price": 4.95}
# ]
#
# Note: Output schema must match input schema exactly:
# - Same field names
# - Same data types
# - No fields added or removed
`;

export enum AUTHENTICATION_TYPES {
  NONE = 'none',
  BASIC = 'basic',
  CONNECTION = 'connection',
}

export enum REST_API_SOURCE_METHODS {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
}

export enum REST_API_DESTINATION_METHODS {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum REST_API_METHODS {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
export enum REST_API_RESPONSE_TYPES {
  raw = 'raw',
  json = 'json',
  line_delimited_json = 'line_delimited_json',
  line_delimited_data = 'line_delimited_data',
  xml = 'xml',
}

export enum REST_API_REQUEST_TYPES {
  csv = 'csv',
  json = 'json',
  formData = 'form-data',
  urlEncoded = 'url-encoded',
}

export enum NUMBER_OF_REQUESTS {
  singlePerRecord = 'single_per_record',
  batch = 'batch',
  oneRequest = 'one_request',
}

export enum COMPONENT_CATEGORY {
  source = 'source',
  transformation = 'transformation',
  function = 'function',
  destination = 'destination',
  workflow = 'workflow',
  note = 'note',
}

export interface ComponentTypeItem {
  id?: string;
  alias?: string;
  is_new?: boolean;
  xy?: number[];
  package_id?: number;
  originalName: string;
  description?: string;
  descriptionHTML?: string;
  noteHeight?: number;
  noteWidth?: number;
  type: COMPONENT_CATEGORY;
  subtype?: COMPONENT_CATEGORY;
  connectionTypes?: string;
  dynamic_connection?: string;
  icon: string;
  componentType: COMPONENT_TYPE;
  message?: string;
  connection?: Partial<Connection>;
  source_connection?: Partial<Connection>;
  destination_connection?: Partial<Connection>;
  package?: Package;
  isHovered?: boolean;
  isSelected?: boolean;
  object_name?: string;
  predicates?: {
    expressions?: ExpressionField[];
    match_type?: string;
  };
  extraFields?: [
    {
      name: string;
      type: string;
    },
  ];
  record_settings?: CSVDestinationType;
  relations?: Relation[];
  percentage?: number;
  partitioned_fields?: Field[];
  ordered_fields?: Field[];
  column_mappings?: Field[];
  windowed_fields?: WindowedField[];
  grouped_fields?: GroupedField[];
  aggregated_fields?: AggregatedField[];
  fields?: Field[];
  partitioning?: string;
  order?: string;
  grouping_type?: string;
  n?: number;
  field_alias?: string;
  query?: string;
  dense?: boolean;
  data_order?: string;
  name?: string;
  inputMin: number;
  inputMax: number;
  outputMin?: number;
  outputMax?: number;
  newBadge?: boolean;
  disableSchemaLoad?: boolean;
  hasDataPreviewRefreshButton?: boolean;
  hasDataPreview?: boolean;
  record_delimiter?: string;
  record_type?: string;
  access_mode?: string;
  customer_id_field_alias?: string;
  account_name_field_alias?: string;
  property_name_field_alias?: string;
  profile_name_field_alias?: string;
  profile_id_field_alias?: string;
  response_type?: REST_API_RESPONSE_TYPES;
  report_aggregation_type?: string;
  time_period_date_field_alias?: string;
  time_period_hour_field_alias?: string;
  hasTimePeriodField?: boolean;
  join_type?: string;
  optimization_type?: string;
  destination_type?: {
    csv_destination_type?: {
      field_delimiter?: string;
      text_qualifier?: string;
      write_header?: boolean;
      escape_character?: string;
      line_ending?: string;
    };
  };
  defaults?: Partial<AnyComponentData>;
  authentication?: string;
  body?: string;
  headers?: any;
  json_path?: string;
  json_consts?: ColumnMap[];
  max_requests?: number;
  method?: string;
  pagination_scheme?: string;
  pagination_sleep_interval?: string;
  password?: string;
  url?: string;
  username?: string;
  batch_size?: number;
  code?: string;
}

export const COMPONENT_TYPES: ComponentTypeItem[] = [
  {
    componentType: COMPONENT_TYPE.STICKY_NOTE_COMPONENT,
    originalName: 'Note',
    inputMin: 0,
    inputMax: 0,
    icon: 'icon-sticky-note',
    type: COMPONENT_CATEGORY.note,
  },
  {
    originalName: 'File Storage',
    description:
      'Use the file storage source component to define the file data source for your data flow: which connection to use, where the files reside and which fields will be used in the package.',
    type: COMPONENT_CATEGORY.source,
    connectionTypes: `${ConnectionTypeName.s3},${ConnectionTypeName.gs},${ConnectionTypeName.gsv2},${ConnectionTypeName.hdfs},${ConnectionTypeName.sftp},${ConnectionTypeName.ftps},${ConnectionTypeName.azureBlobStorage}`,
    icon: 'icon-file-storage',
    componentType: COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT,
    extraFields: [
      {
        name: 'file_path',
        type: 'string',
      },
    ],
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    hasDataPreview: true,
    defaults: {
      record_delimiter: RECORD_DELIMITER.NEW_LINE,
      record_type: RECORD_TYPE.DELIMITED,
      record_settings: {
        escape_character: '"',
        field_delimiter: ',',
        skip_header: true,
        text_qualifier: '',
      },
      char_encoding: 'utf-8',
      manifest_connection: {},
      manifest_path: '',
    },
  },
  {
    originalName: 'Database',
    type: COMPONENT_CATEGORY.source,
    connectionTypes: `${ConnectionTypeName.vertica},${ConnectionTypeName.azureSynapseAnalytics},${ConnectionTypeName.googleCloudSql},${ConnectionTypeName.googleCloudPostgres},${ConnectionTypeName.alloy},${ConnectionTypeName.herokuPostgres},${ConnectionTypeName.sqlserver},${ConnectionTypeName.postgres},${ConnectionTypeName.mysql},${ConnectionTypeName.oracle},${ConnectionTypeName.oracleADW},${ConnectionTypeName.redshift},${ConnectionTypeName.snowflake},${ConnectionTypeName.athena},${ConnectionTypeName.db2},${ConnectionTypeName.as400}`,
    icon: 'icon-database',
    componentType: COMPONENT_TYPE.DATABASE_SOURCE_COMPONENT,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    hasDataPreview: true,
    defaults: {
      access_mode: 'table',
      parallel: 1,
      before_action: 'none',
      split_by_column_name: '',
      query: '',
      schema_name: '',
      table_name: '',
      where_clause: '',
    },
  },
  {
    originalName: 'Amazon Redshift',
    type: COMPONENT_CATEGORY.source,
    connectionTypes: ConnectionTypeName.redshift,
    icon: 'icon-amazon-redshift',
    componentType: COMPONENT_TYPE.AMAZON_REDSHIFT_SOURCE_COMPONENT,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    hasDataPreview: true,
    defaults: {
      access_mode: 'table',
      query: '',
      schema_name: '',
      table_name: '',
      where_clause: '',
    },
  },
  {
    originalName: 'Google BigQuery',
    type: COMPONENT_CATEGORY.source,
    icon: 'icon-big-query',
    connectionTypes: `${ConnectionTypeName.bigQuery},${ConnectionTypeName.bigQueryV2}`,
    componentType: COMPONENT_TYPE.BIG_QUERY_SOURCE_COMPONENT,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      use_legacy_sql: true,
      stored_procedure: false,
      access_mode: 'table',
      query: '',
      schema_name: '',
      table_name: '',
    },
    hasDataPreview: true,
  },
  {
    originalName: 'MongoDB',
    type: COMPONENT_CATEGORY.source,
    connectionTypes: ConnectionTypeName.mongo,
    icon: 'icon-mongo',
    componentType: COMPONENT_TYPE.MONGO_SOURCE_COMPONENT,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      where_clause: '',
      access_mode: 'table',
      query: '',
      schema_name: '',
    },
    hasDataPreview: true,
  },
  {
    originalName: 'Facebook Ads Insights',
    type: COMPONENT_CATEGORY.source,
    connectionTypes: ConnectionTypeName.facebookAdsInsights,
    disableSchemaLoad: true,
    icon: 'icon-facebook',
    componentType: COMPONENT_TYPE.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    hasDataPreviewRefreshButton: true,
    defaults: {
      api_version: 'v21.0',
      level: 'account',
      report_date_range_type: 'last_7d',
      report_date_range_min: '',
      report_date_range_max: '',
      manual_breakdown: 'Default',
      time_increment: 'all_days',
      filtering: '',
      action_report_time: 'impression',
      report_on: 'all',
      ad_accounts: ['any'],
      with_custom_fields: false,
    },
    hasDataPreview: true,
  },
  {
    originalName: 'Google Ads',
    type: COMPONENT_CATEGORY.source,
    connectionTypes: ConnectionTypeName.adwords,
    icon: 'icon-google-adwords',
    componentType: COMPONENT_TYPE.ADWORDS_SOURCE_COMPONENT,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    disableSchemaLoad: true,
    hasDataPreview: false,
    defaults: {
      // api version default selected in designer service
      api_version: 'AdsV18',
      report_type: '',
      customer_ids: '',
      report_date_range_type: 'LAST_7_DAYS',
      report_date_range_min: '',
      report_date_range_max: '',
      customer_id_field_alias: 'CustomerID',
      report_on: 'all',
    },
  },
  {
    originalName: 'Google Analytics',
    type: COMPONENT_CATEGORY.source,
    connectionTypes: ConnectionTypeName.analytics,
    icon: 'icon-google-analytics',
    componentType: COMPONENT_TYPE.ANALYTICS_SOURCE_COMPONENT,
    disableSchemaLoad: true,
    hasDataPreview: true,
    hasDataPreviewRefreshButton: true,
    extraFields: [
      {
        name: 'file_path',
        type: 'string',
      },
    ],
    defaults: {
      profile_ids: '',
      report_date_range_type: 'CUSTOM_DATE',
      account_name_field_alias: 'account_name',
      property_name_field_alias: 'property_name',
      profile_name_field_alias: 'profile_name',
      profile_id_field_alias: 'profile_id',
      report_on: 'all',
      report_date_range_min: '7daysAgo',
      report_date_range_max: 'today',
      manual_breakdown: 'Default',
      segment: '',
      filters: null,
      sort: null,
      include_empty_rows: true,
      report_sampling_level: 'DEFAULT',
      dimensions: [],
      metrics: [],
    } as Partial<Analytics4SourceComponentData>,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
  },
  {
    originalName: 'Google Analytics - GA4',
    type: COMPONENT_CATEGORY.source,
    connectionTypes: ConnectionTypeName.analyticsV4,
    icon: 'icon-google-analytics',
    componentType: COMPONENT_TYPE.ANALYTICS_GA4_SOURCE_COMPONENT,
    disableSchemaLoad: true,
    hasDataPreview: true,
    hasDataPreviewRefreshButton: true,
    extraFields: [
      {
        name: 'file_path',
        type: 'string',
      },
    ],
    defaults: {
      profile_ids: '',
      report_date_range_type: 'CUSTOM_DATE',
      account_name_field_alias: 'account_name',
      property_name_field_alias: 'property_name',
      property_id_field_alias: 'property_id',
      account_id_field_alias: 'account_id',
      report_on: 'all',
      report_date_range_min: '7daysAgo',
      report_date_range_max: 'today',
      manual_breakdown: 'Default',
      segment: '',
      filters: null,
      sort: null,
      report_sampling_level: 'DEFAULT',
      api_version: 'v1beta',
      dimensions: [],
      metrics: [],
      include_empty_rows: false,
    } as Partial<Analytics4SourceComponentData>,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
  },
  {
    originalName: 'REST API',
    connectionTypes: `${ConnectionTypeName.analytics},${ConnectionTypeName.facebookAdsInsights},${ConnectionTypeName.hubspot},${ConnectionTypeName.hubspotOAuth},${ConnectionTypeName.facebookAds},${ConnectionTypeName.intercom},${ConnectionTypeName.xero},${ConnectionTypeName.linkedin},${ConnectionTypeName.instagram},${ConnectionTypeName.googleSheets},${ConnectionTypeName.googleDrive},${ConnectionTypeName.youtube},${ConnectionTypeName.shopify},${ConnectionTypeName.salesforce},${ConnectionTypeName.marketingCloud},${ConnectionTypeName.marketingCloudRest}`,
    type: COMPONENT_CATEGORY.source,
    icon: 'icon-rest-api',
    disableSchemaLoad: false,
    hasDataPreview: true,
    hasDataPreviewRefreshButton: true,
    componentType: COMPONENT_TYPE.REST_API_SOURCE_COMPONENT,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      method: REST_API_METHODS.GET,
      response_type: REST_API_RESPONSE_TYPES.json,
      pagination_scheme: 'auto',
      use_pagination: false,
      pagination_sleep_interval: '0',
      json_path: '$',
      authentication: AUTHENTICATION_TYPES.NONE,
      body: '',
      max_requests: '',
      password: '',
      username: '',
      url: '',
    },
  },
  {
    originalName: 'Curl',
    descriptionHTML:
      'A wrapper to the Curl() function. This component executes an API call per record <a href="https://www.integrate.io/etl/docs/" target="_blank">(documentation here)</a>',
    connectionTypes: `${ConnectionTypeName.analytics},${ConnectionTypeName.facebookAdsInsights},${ConnectionTypeName.hubspot},${ConnectionTypeName.facebookAds},${ConnectionTypeName.intercom},${ConnectionTypeName.xero},${ConnectionTypeName.linkedin},${ConnectionTypeName.instagram},${ConnectionTypeName.googleSheets},${ConnectionTypeName.googleDrive},${ConnectionTypeName.youtube},${ConnectionTypeName.shopify},${ConnectionTypeName.salesforce},${ConnectionTypeName.marketingCloud},${ConnectionTypeName.marketingCloudRest}`,
    type: COMPONENT_CATEGORY.transformation,
    subtype: COMPONENT_CATEGORY.function,
    newBadge: true,
    icon: 'icon-rest-api',
    disableSchemaLoad: false,
    hasDataPreview: true,
    hasDataPreviewRefreshButton: true,
    componentType: COMPONENT_TYPE.REST_API_TRANSFORMATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      method: REST_API_METHODS.GET,
      response_type: REST_API_RESPONSE_TYPES.json,
      pagination_scheme: 'auto',
      use_pagination: false,
      pagination_sleep_interval: '0',
      json_path: '$',
      authentication: AUTHENTICATION_TYPES.NONE,
      body: '',
      max_requests: '',
      password: '',
      username: '',
      url: '',
    },
  },
  {
    originalName: 'REST API',
    connectionTypes: `${ConnectionTypeName.analytics},${ConnectionTypeName.facebookAdsInsights},${ConnectionTypeName.hubspot},${ConnectionTypeName.hubspotOAuth},${ConnectionTypeName.facebookAds},${ConnectionTypeName.intercom},${ConnectionTypeName.xero},${ConnectionTypeName.linkedin},${ConnectionTypeName.instagram},${ConnectionTypeName.googleSheets},${ConnectionTypeName.googleDrive},${ConnectionTypeName.youtube},${ConnectionTypeName.shopify},${ConnectionTypeName.salesforce},${ConnectionTypeName.marketingCloud},${ConnectionTypeName.marketingCloudRest}`,
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-rest-api',
    disableSchemaLoad: false,
    hasDataPreview: true,
    hasDataPreviewRefreshButton: true,
    componentType: COMPONENT_TYPE.REST_API_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    newBadge: true,
    defaults: {
      method: REST_API_METHODS.POST,
      request_type: REST_API_REQUEST_TYPES.json,
      authentication: AUTHENTICATION_TYPES.NONE,
      max_requests: '',
      password: '',
      username: '',
      url: '',
      batch_size: 10,
      number_of_requests: NUMBER_OF_REQUESTS.singlePerRecord,
      save_responses_to_file: false,
      fail_on_error_response: true,
    } as Partial<RestApiDestinationComponentData>,
  },
  {
    originalName: 'Salesforce',
    type: COMPONENT_CATEGORY.source,
    connectionTypes: ConnectionTypeName.salesforce,
    icon: 'icon-salesforce',
    componentType: COMPONENT_TYPE.SALESFORCE_SOURCE_COMPONENT,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    disableSchemaLoad: true,
    hasDataPreviewRefreshButton: true,
    defaults: {
      object_name: '',
      where_clause: '',
      source_action: 'query',
      access_mode: 'object',
      query: '',
      api_version: SALESFORCE_API_VERSION.V2,
    },
    hasDataPreview: true,
  },
  {
    originalName: 'Bing Ads',
    type: COMPONENT_CATEGORY.source,
    icon: 'icon-bing-ads',
    connectionTypes: ConnectionTypeName.bingAds,
    componentType: COMPONENT_TYPE.BING_ADS_SOURCE_COMPONENT,
    disableSchemaLoad: true,
    hasDataPreview: false,
    defaults: {
      api_version: 'v13',
      accounts_campaigns: '',
      report_type: '',
      columns: [],
      report_aggregation_type: 'SUMMARY',
      report_date_range_type: 'TODAY',
      report_date_range_min: '',
      report_date_range_max: '',
      time_zone: '',
      report_on: 'all',
    },
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
  },
  {
    originalName: 'NetSuite',
    type: COMPONENT_CATEGORY.source,
    icon: 'icon-netsuite',
    connectionTypes: ConnectionTypeName.netsuite,
    componentType: COMPONENT_TYPE.NET_SUITE_SOURCE_COMPONENT,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    hasDataPreview: true,
    defaults: {
      access_mode: 'table',
      query: '',
      schema_name: '',
      table_name: '',
      where_clause: '',
    },
  },
  {
    originalName: 'Google Cloud Spanner',
    type: COMPONENT_CATEGORY.source,
    icon: 'icon-spanner',
    connectionTypes: ConnectionTypeName.googleCloudSpanner,
    componentType: COMPONENT_TYPE.SPANNER_SOURCE_COMPONENT,
    inputMin: 0,
    inputMax: 0,
    outputMin: 1,
    outputMax: 1,
    hasDataPreview: true,
    defaults: {
      access_mode: 'table',
      query: '',
      schema_name: '',
      table_name: '',
      where_clause: '',
    },
  },
  {
    originalName: 'Python Transformation',
    connectionTypes: `${ConnectionTypeName.s3}`,
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-lambda-transformation',
    componentType: COMPONENT_TYPE.LAMBDA_TRANSFORMATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
    newBadge: true,
    defaults: {
      code: PYTHON_EXAMPLE_CODE,
      batch_size: 10,
      fields: [],
    },
  },
  {
    originalName: 'AI Transformations',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-ai-transformations',
    componentType: COMPONENT_TYPE.AI_TRANSFORMATIONS,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
    defaults: {},
  },
  {
    originalName: 'Select',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-select',
    componentType: COMPONENT_TYPE.SELECT_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
  },
  {
    originalName: 'Sort',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-sort',
    componentType: COMPONENT_TYPE.SORT_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
  },
  {
    originalName: 'Rank',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-rank',
    componentType: COMPONENT_TYPE.RANK_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
  },
  {
    originalName: 'Limit',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-limit',
    componentType: COMPONENT_TYPE.LIMIT_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
  },
  {
    originalName: 'Window',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-window',
    componentType: COMPONENT_TYPE.WINDOW_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      partitioned_fields: [],
      ordered_fields: [],
      windowed_fields: [],
    },
  },
  {
    originalName: 'Sample',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-sample',
    componentType: COMPONENT_TYPE.SAMPLE_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      percentage: '' as any,
    },
  },
  {
    originalName: 'Join',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-join',
    componentType: COMPONENT_TYPE.JOIN_COMPONENT,
    inputMin: 2,
    inputMax: 2,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      join_type: 'inner',
      optimization_type: 'default',
      relations: [],
    },
  },
  {
    originalName: 'Cross join',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-cross-join',
    componentType: COMPONENT_TYPE.CROSS_JOIN_COMPONENT,
    inputMin: 2,
    inputMax: 2,
    outputMin: 1,
    outputMax: 1,
  },
  {
    originalName: 'Clone',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-clone',
    componentType: COMPONENT_TYPE.CLONE_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 10,
  },
  {
    originalName: 'Union',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-union',
    componentType: COMPONENT_TYPE.UNION_COMPONENT,
    inputMin: 2,
    inputMax: 2,
    outputMin: 1,
    outputMax: 1,
  },
  {
    originalName: 'Filter',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-filter',
    componentType: COMPONENT_TYPE.FILTER_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      predicates: {
        match_type: 'all',
        expressions: [],
      },
    },
  },
  {
    originalName: 'Assert',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-assert',
    componentType: COMPONENT_TYPE.ASSERT_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      message: 'Assertion failed',
      predicates: {
        match_type: 'all',
        expressions: [],
      },
    },
  },
  {
    originalName: 'Aggregate',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-aggregate',
    componentType: COMPONENT_TYPE.AGGREGATE_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      grouping_type: 'all',
      grouped_fields: [],
      aggregated_fields: [],
    },
  },
  {
    originalName: 'Cube',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-cube',
    componentType: COMPONENT_TYPE.CUBE_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
    defaults: {
      grouped_fields: [],
      aggregated_fields: [],
    },
  },
  {
    originalName: 'Distinct',
    type: COMPONENT_CATEGORY.transformation,
    icon: 'icon-distinct',
    componentType: COMPONENT_TYPE.DISTINCT_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 1,
    outputMax: 1,
  },
  {
    originalName: 'Amazon Redshift',
    type: COMPONENT_CATEGORY.destination,
    connectionTypes: ConnectionTypeName.redshift,
    icon: 'icon-amazon-redshift',
    componentType: COMPONENT_TYPE.AMAZON_REDSHIFT_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      create_table: true,
      add_columns: true,
      operation_type: 'append',
      schema_name: '',
      intermediate_compression_type: 'none',
      max_errors: 0,
      truncate_columns: true,
      trim_blanks: true,
      empty_as_null: false,
      blank_as_null: false,
      null_string: '\\N',
      replacement_char: '?',
      round_decimals: true,
      explicit_ids: false,
      comp_update: 'auto',
      comp_rows: 100000,
      column_mappings: [],
    },
  },
  {
    originalName: 'Google BigQuery',
    type: COMPONENT_CATEGORY.destination,
    connectionTypes: `${ConnectionTypeName.bigQuery},${ConnectionTypeName.bigQueryV2}`,
    icon: 'icon-big-query',
    componentType: COMPONENT_TYPE.BIG_QUERY_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      max_bad_records: 0,
      create_table: true,
      add_columns: true,
      operation_type: 'append',
      column_mappings: [],
    },
  },
  {
    originalName: 'Database',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-database',
    connectionTypes: `${ConnectionTypeName.vertica},${ConnectionTypeName.azureSynapseAnalytics},${ConnectionTypeName.googleCloudSql},${ConnectionTypeName.googleCloudPostgres},${ConnectionTypeName.alloy},${ConnectionTypeName.herokuPostgres},${ConnectionTypeName.sqlserver},${ConnectionTypeName.postgres},${ConnectionTypeName.mysql},${ConnectionTypeName.oracle},${ConnectionTypeName.oracleADW},${ConnectionTypeName.redshift},${ConnectionTypeName.db2}`,
    componentType: COMPONENT_TYPE.DATABASE_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      create_table: true,
      add_columns: true,
      operation_type: 'append',
      schema_name: '',
      batch_size: 1000,
      parallel: 1,
      single_transaction_per_batch: false,
      identity_insert_sql_server: false,
      pre_action_sql: '',
      column_mappings: [],
    },
  },
  {
    originalName: 'MongoDB',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-mongo',
    connectionTypes: ConnectionTypeName.mongo,
    componentType: COMPONENT_TYPE.MONGO_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      operation_type: 'append',
      column_mappings: [],
    },
  },
  {
    originalName: 'File storage',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-file-storage',
    connectionTypes: `${ConnectionTypeName.s3},${ConnectionTypeName.gs},${ConnectionTypeName.gsv2},${ConnectionTypeName.hdfs},${ConnectionTypeName.sftp},${ConnectionTypeName.ftps},${ConnectionTypeName.azureBlobStorage}`,
    componentType: COMPONENT_TYPE.CLOUD_STORAGE_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      bucket: '',
      path: '',
      file_names: 'default',
      prefix: '',
      suffix: null,
      destination_type: {
        csv_destination_type: {
          field_delimiter: ',',
          text_qualifier: '',
          write_header: true,
          escape_character: '"',
          line_ending: 'unix', // this sets initial value on ui
        },
      },
      compression_type: 'none',
      enforce_single_file: false,
      operation_type: 'fail_if_exists',
      char_encoding: 'utf-8',
    },
  },
  {
    originalName: 'Google Spanner',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-spanner',
    connectionTypes: ConnectionTypeName.googleCloudSpanner,
    componentType: COMPONENT_TYPE.SPANNER_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      create_table: true,
      add_columns: true,
      operation_type: 'append',
    },
  },
  {
    originalName: 'Snowflake',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-snowflake',
    connectionTypes: ConnectionTypeName.snowflake,
    componentType: COMPONENT_TYPE.SNOWFLAKE_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      warehouse_name: '',
      database_name: '',
      create_table: true,
      add_columns: true,
      empty_as_null: true,
      operation_type: 'append',
      max_errors: 0,
      truncate_columns: true,
      column_mappings: [],
      null_string: '\\N',
      schema_name: '',
    },
  },
  {
    originalName: 'Salesforce',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-salesforce',
    connectionTypes: ConnectionTypeName.salesforce,
    componentType: COMPONENT_TYPE.SALESFORCE_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      object_name: '',
      operation_type: 'insert',
      id_field: '',
      batch_size: 1000,
      parallel: 1,
      max_errors: 0,
      track_errors: false,
      errors_output_path: '',
      column_mappings: [],
      api_version: SALESFORCE_API_VERSION.V2,
    },
  },
  {
    originalName: 'Salesforce SOAP',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-salesforce',
    connectionTypes: ConnectionTypeName.salesforceSoap,
    componentType: COMPONENT_TYPE.SALESFORCE_SOAP_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      object_name: '',
      operation_type: 'insert',
      id_field: '',
      batch_size: 200,
      parallel: 1,
      max_errors: 0,
      track_errors: false,
      errors_output_path: '',
      column_mappings: [],
    },
  },
  {
    originalName: 'NetSuite SOAP',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-netsuite',
    connectionTypes: ConnectionTypeName.netsuitesoap,
    componentType: COMPONENT_TYPE.NETSUITE_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      object_name: '',
      operation_type: 'insert',
      id_field: '',
      batch_size: 100,
      max_errors: 0,
      column_mappings: [],
    },
  },
  {
    originalName: 'Facebook Ads',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-facebook',
    connectionTypes: ConnectionTypeName.facebookAds,
    componentType: COMPONENT_TYPE.FACEBOOK_ADS_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      object_name: '',
      operation_type: 'insert',
      batch_size: 100,
      account_id: '',
      max_errors: 0,
      column_mappings: [],
    },
  },
  {
    originalName: 'HubSpot',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-hubspot',
    connectionTypes: `${ConnectionTypeName.hubspot},${ConnectionTypeName.hubspotOAuth}`,
    componentType: COMPONENT_TYPE.HUBSPOT_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      object_name: '',
      operation_type: 'create',
      batch_size: 10,
      account_id: '',
      max_errors: 0,
      column_mappings: [],
    },
  },
  {
    originalName: 'Google Ads',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-google-adwords',
    connectionTypes: ConnectionTypeName.adwords,
    componentType: COMPONENT_TYPE.GOOGLE_ADS_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      object_name: '',
      operation_type: 'insert',
      id_field: '',
      customer_id: '',
      login_customer_id: '',
      column_mappings: [],
      batch_size: 100,
      max_errors: 0,
    },
  },
  {
    originalName: 'TikTok Ads',
    type: COMPONENT_CATEGORY.destination,
    icon: 'icon-tiktokads',
    connectionTypes: ConnectionTypeName.tiktokads,
    componentType: COMPONENT_TYPE.TIKTOK_ADS_DESTINATION_COMPONENT,
    inputMin: 1,
    inputMax: 1,
    outputMin: 0,
    outputMax: 0,
    defaults: {
      object_name: '',
      operation_type: 'insert',
      id_field: '',
      batch_size: 100,
      max_errors: 0,
      column_mappings: [],
    },
  },
  {
    originalName: 'Run package',
    type: COMPONENT_CATEGORY.workflow,
    icon: 'icon-run-package',
    componentType: COMPONENT_TYPE.RUN_PACKAGE_COMPONENT,
    inputMin: 1,
    inputMax: 100,
    outputMin: 1,
    outputMax: 100,
    defaults: {
      operator: 'and',
    },
  },
  {
    originalName: 'Execute SQL',
    type: COMPONENT_CATEGORY.workflow,
    icon: 'icon-execute-sql',
    connectionTypes: `${ConnectionTypeName.vertica},${ConnectionTypeName.azureSynapseAnalytics},${ConnectionTypeName.googleCloudPostgres},${ConnectionTypeName.alloy},${ConnectionTypeName.googleCloudSql},${ConnectionTypeName.bigQuery},${ConnectionTypeName.bigQueryV2},${ConnectionTypeName.redshift},${ConnectionTypeName.mysql},${ConnectionTypeName.herokuPostgres},${ConnectionTypeName.postgres},${ConnectionTypeName.sqlserver},${ConnectionTypeName.oracle},${ConnectionTypeName.oracleADW},${ConnectionTypeName.snowflake},${ConnectionTypeName.athena},${ConnectionTypeName.db2},${ConnectionTypeName.as400}`,
    componentType: COMPONENT_TYPE.EXECUTE_SQL_COMPONENT,
    inputMin: 1,
    inputMax: 100,
    outputMin: 1,
    outputMax: 100,
    defaults: {
      operator: 'and',
      result_type: 'none',
    },
  },
  {
    originalName: 'File mover',
    type: COMPONENT_CATEGORY.workflow,
    icon: 'icon-file-mover',
    connectionTypes: `${ConnectionTypeName.s3},${ConnectionTypeName.gs},${ConnectionTypeName.gsv2},${ConnectionTypeName.hdfs},${ConnectionTypeName.sftp},${ConnectionTypeName.ftps},${ConnectionTypeName.azureBlobStorage}`,
    componentType: COMPONENT_TYPE.FILE_MOVER_COMPONENT,
    inputMin: 1,
    inputMax: 100,
    outputMin: 1,
    outputMax: 100,
    defaults: {
      source_connection: {},
      destination_connection: {},
      operator: 'and',
      delete_files_from_source: true,
      fail_on_empty_source: true,
    },
  },
  {
    originalName: 'PDF to CSV',
    type: COMPONENT_CATEGORY.workflow,
    icon: 'icon-file-mover',
    connectionTypes: `${ConnectionTypeName.s3},${ConnectionTypeName.gs},${ConnectionTypeName.gsv2},${ConnectionTypeName.hdfs},${ConnectionTypeName.sftp},${ConnectionTypeName.ftps},${ConnectionTypeName.azureBlobStorage}`,
    componentType: COMPONENT_TYPE.PDF_TO_CSV_COMPONENT,
    inputMin: 1,
    inputMax: 100,
    outputMin: 1,
    outputMax: 100,
    defaults: {
      source_connection: {},
      destination_connection: {},
      operator: 'and',
      delete_files_from_source: true,
      fail_on_empty_source: true,
    },
  },
];

export const COMPONENT_TYPES_SOURCES = COMPONENT_TYPES.filter(
  (component) => component.type === COMPONENT_CATEGORY.source,
);
export const COMPONENT_TYPES_TRANSFORMATIONS = COMPONENT_TYPES.filter(
  (component) =>
    component.type === COMPONENT_CATEGORY.transformation && component.subtype !== COMPONENT_CATEGORY.function,
);
export const COMPONENT_TYPES_FUNCTIONS = COMPONENT_TYPES.filter(
  (component) =>
    component.type === COMPONENT_CATEGORY.transformation && component.subtype === COMPONENT_CATEGORY.function,
);
export const COMPONENT_TYPES_DESTINATIONS = COMPONENT_TYPES.filter(
  (component) => component.type === COMPONENT_CATEGORY.destination,
);
export const COMPONENT_TYPES_WORKFLOWS = COMPONENT_TYPES.filter(
  (component) => component.type === COMPONENT_CATEGORY.workflow,
);
