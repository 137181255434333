import { SelectOption } from '../common/components/forms/xp-select.component';

export const SORT_DIRECTION_ASC = 'ASC';
export const SORT_DIRECTION_DESC = 'DESC';

export const SORT_DIRECTION: SelectOption[] = [
  {
    text: 'fields-collection.editors.sort-direction-picker.ASC',
    value: SORT_DIRECTION_ASC,
    translate: true,
  },
  {
    text: 'fields-collection.editors.sort-direction-picker.DESC',
    value: SORT_DIRECTION_DESC,
    translate: true,
  },
];
