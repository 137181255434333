import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

function formatDate(value: string, timezone: string): string {
  if (value.endsWith('Z')) {
    value = value.slice(0, -1);
  }
  let momentDate;
  if (timezone) {
    momentDate = moment.tz(value, timezone);
  } else {
    momentDate = moment(value);
  }
  const year = momentDate.format('YYYY');
  const day = momentDate.format('MMM Do');
  const time = momentDate.format('HH:mm');
  const isCurrentYear = year === moment(Date.now()).format('YYYY');

  return `${day}${isCurrentYear ? '' : `, ${year}`} at ${time}`;
}

@Pipe({ name: 'xpDateWithTime' })
export class XpDateWithTimePipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: string, timezone: string): string {
    return value ? formatDate(value, timezone) : '';
  }
}
