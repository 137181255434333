import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '../../store';
import { selectAccountId } from '../../account/store/account.selectors';
import { Package, PackageTemplate } from '../package.models';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { CustomHeaders, ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class PackagesResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  get(package_id: number, params?: ListParams, account_id?: string, headers?: CustomHeaders): Observable<Package> {
    if (account_id) {
      return this.http.get<Package>(`${this.apiRoot}/${account_id}/api/packages/${package_id}`, {
        params: params as QueryParams,
        headers: headers as HttpHeaders,
      });
    }

    return this.store.pipe(
      selectAccountId,
      mergeMap((store_account_id) =>
        this.http.get<Package>(`${this.apiRoot}/${store_account_id}/api/packages/${package_id}`, {
          params: params as QueryParams,
          headers: headers as HttpHeaders,
        }),
      ),
    );
  }

  save(
    body: Partial<Package> | { source_package_id: number; source_package_name: string },
    params?: ListParams,
  ): Observable<Package> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Package>(`${this.apiRoot}/${account_id}/api/packages`, body, { params: params as QueryParams }),
      ),
    );
  }

  update(package_id: number, body: Partial<Package>, params?: ListParams): Observable<Package> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.put<Package>(`${this.apiRoot}/${account_id}/api/packages/${package_id}`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  remove(package_id: number, params?: ListParams): Observable<any> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.delete<Package>(`${this.apiRoot}/${account_id}/api/packages/${package_id}`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  query(params?: ListParams): Observable<Package[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Package[]>(`${this.apiRoot}/${account_id}/api/packages`, { params: params as QueryParams }),
      ),
    );
  }

  search(params?: ListParams): Observable<Package[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Package[]>(`${this.apiRoot}/${account_id}/api/packages/search`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  validate(package_id: number, params?: ListParams): Observable<Package> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Package>(
          `${this.apiRoot}/${account_id}/api/packages/${package_id}/validations`,
          {},
          {
            params: params as QueryParams,
          },
        ),
      ),
    );
  }

  validateStatus(package_id: number, validation_id: number, params?: ListParams): Observable<Package> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Package>(
          `${this.apiRoot}/${account_id}/api/packages/${package_id}/validations/${validation_id}`,
          { params: params as QueryParams },
        ),
      ),
    );
  }

  export(package_id: number, params?: ListParams): Observable<Package> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Package>(`${this.apiRoot}/${account_id}/api/packages/${package_id}/export`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  versions(package_id: number, params?: ListParams): Observable<Package[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<Package[]>(`${this.apiRoot}/${account_id}/api/packages/${package_id}/snapshots`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  version(
    package_id: number,
    version_id: number,
    params?: ListParams,
    account_id?: string,
    headers?: CustomHeaders,
  ): Observable<Package> {
    if (account_id) {
      return this.http.get<Package>(
        `${this.apiRoot}/${account_id}/api/packages/${package_id}/snapshots/${version_id}`,
        { params: params as QueryParams, headers: headers as HttpHeaders },
      );
    }

    return this.store.pipe(
      selectAccountId,
      mergeMap((accountId) =>
        this.http.get<Package>(`${this.apiRoot}/${accountId}/api/packages/${package_id}/snapshots/${version_id}`, {
          params: params as QueryParams,
          headers: headers as HttpHeaders,
        }),
      ),
    );
  }

  majorVersion(package_id: number, body: Partial<Package>, params?: ListParams): Observable<Package> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Package>(`${this.apiRoot}/${account_id}/api/packages/${package_id}/major_version`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  rollback(package_id: number, version_id: number, body?: Package, params?: ListParams): Observable<Package> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<Package>(
          `${this.apiRoot}/${account_id}/api/packages/${package_id}/snapshots/${version_id}/rollback`,
          body,
          { params: params as QueryParams },
        ),
      ),
    );
  }

  templates(params?: ListParams): Observable<PackageTemplate[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<PackageTemplate[]>(`${this.apiRoot}/${account_id}/api/packages/templates`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  invokeLambda(package_id: number, body: any, params?: ListParams): Observable<any> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<any>(`${this.apiRoot}/${account_id}/api/packages/${package_id}/invoke_lambda`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }
}
