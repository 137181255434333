import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
export interface LabServicesResponse {
  callback?: string;
  result_object?: any;
  result?: any;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LabServicesResource {
  constructor() {}

  // eslint-disable-next-line class-methods-use-this
  evaluate(data: any): Promise<LabServicesResponse> {
    return window
      .fetch(environment.CONSOLE_URL, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .catch((error) => ({ error }));
  }

  evaluateGlobalVariables(data: any): Promise<LabServicesResponse> {
    return window
      .fetch(environment.CONSOLE_URL.replace('evaluate', 'evaluate-global-variables'), {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .catch((error) => ({ error }));
  }
}
